@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot?gtisf3');
  src: url('../../fonts/icomoon.eot?gtisf3#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?gtisf3') format('truetype'),
    url('../../fonts/icomoon.woff?gtisf3') format('woff'),
    url('../../fonts/icomoon.svg?gtisf3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logout:before {
  content: "\e95c";
  color: #c73037;
}
.icon-questionnaire-line:before {
  content: "\e95d";
  color: var(--heading-color);
}
.icon-user-3-line:before {
  content: "\e95e";
  color: var(--heading-color);
}
.icon-district_plus:before {
  content: "\e95a";
  color: var(--heading-color);
}
.icon-district_minus:before {
  content: "\e95b";
  color: var(--heading-color);
}
.icon-vendor-consumer-request-list:before {
  content: "\e953";
}

.icon-vendor-application-request-list:before {
  content: "\e954";
}

.icon-vendor-my-applications:before {
  content: "\e955";
}

.icon-vendor-my-profile:before {
  content: "\e956";
}

.icon-vendor-consumer-subsidy-status:before {
  content: "\e957";
}

.icon-vendor-intrested-consumer:before {
  content: "\e958";
}

.icon-vendor-consumer-request-list1:before {
  content: "\e959";
}

.icon-flat-color-icons_end-call:before {
  content: "\e950";
  color: #f8c60d;
}

.icon-fluent_status-16-regular .path1:before {
  content: "\e951";
  color: rgb(201, 218, 233);
}

.icon-fluent_status-16-regular .path2:before {
  content: "\e952";
  margin-left: -1em;
  color: var(--heading-color);
}

.icon-user-2-line:before {
  content: "\e94f";
  color: var(--bg-color);
}

.icon-map:before {
  content: "\e94e";
  color: var(--bg-color);
}

.icon-building-line:before {
  content: "\e94d";
  color: var(--heading-color);
}

.icon-info-circle:before {
  content: "\e94a";
  color: var(--heading-color);
}

.icon-check .path1:before {
  content: "\e94b";
  color: rgb(31, 168, 34);
}

.icon-check .path2:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-fi_2907972:before {
  content: "\e93c";
  color: var(--heading-color);
}

.icon-fi_2910914:before {
  content: "\e93d";
  color: var(--heading-color);
}

.icon-fi_7542110:before {
  content: "\e93e";
  color: var(--heading-color);
}

.icon-fi_10348976:before {
  content: "\e93f";
  color: var(--heading-color);
}

.icon-fi_10349096:before {
  content: "\e940";
  color: var(--heading-color);
}

.icon-Group-1:before {
  content: "\e941";
  color: var(--heading-color);
}

.icon-home:before {
  content: "\e942";
}

.icon-ic_help:before {
  content: "\e943";
  color: var(--heading-color);
}

.icon-link-m:before {
  content: "\e944";
  color: var(--heading-color);
}

.icon-login:before {
  content: "\e945";
  color: var(--heading-color);
}

.icon-mobile:before {
  content: "\e946";
  color: var(--heading-color);
}

.icon-mobile_alt:before {
  content: "\e947";
  color: var(--heading-color);
}

.icon-call:before {
  content: "\e948";
  color: var(--heading-color);
}

.icon-fi_631180:before {
  content: "\e949";
  color: var(--heading-color);
}

.icon-Group1:before {
  content: "\e93b";
  color: var(--heading-color);
}

.icon-link:before {
  content: "\e93a";
  color: var(--heading-color);
}

.icon-file-list-2-line:before {
  content: "\e939";
  color: var(--bg-color);
}

.icon-Group:before {
  content: "\e937";
  color: var(--heading-color);
}

.icon-fi_7655679:before {
  content: "\e938";
  color: var(--heading-color);
}

.icon-file .path1:before {
  content: "\e920";
  color: rgb(1, 82, 150);
}

.icon-file .path2:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-file .path3:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-file .path4:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-file .path5:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-file .path6:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-file .path7:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-file .path8:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-Group-180 .path1:before {
  content: "\e928";
  color: rgb(1, 82, 150);
}

.icon-Group-180 .path2:before {
  content: "\e929";
  margin-left: -0.9794921875em;
  color: rgb(1, 82, 150);
}

.icon-Group-180 .path3:before {
  content: "\e92a";
  margin-left: -0.9794921875em;
  color: rgb(1, 1, 1);
}

.icon-Group-180 .path4:before {
  content: "\e92b";
  margin-left: -0.9794921875em;
  color: rgb(0, 0, 0);
}

.icon-Group-180 .path5:before {
  content: "\e92c";
  margin-left: -0.9794921875em;
  color: rgb(1, 1, 1);
}

.icon-Group-180 .path6:before {
  content: "\e92d";
  margin-left: -0.9794921875em;
  color: rgb(0, 0, 0);
}

.icon-Group-180 .path7:before {
  content: "\e92e";
  margin-left: -0.9794921875em;
  color: rgb(255, 255, 255);
}

.icon-Group-180 .path8:before {
  content: "\e92f";
  margin-left: -0.9794921875em;
  color: rgb(0, 0, 0);
}

.icon-Group-180 .path9:before {
  content: "\e930";
  margin-left: -0.9794921875em;
  color: rgb(255, 255, 255);
}

.icon-Group-180 .path10:before {
  content: "\e931";
  margin-left: -0.9794921875em;
  color: rgb(255, 255, 255);
}

.icon-Group-180 .path11:before {
  content: "\e932";
  margin-left: -0.9794921875em;
  color: rgb(0, 0, 0);
}

.icon-Group-186 .path1:before {
  content: "\e933";
  color: rgb(1, 82, 150);
}

.icon-Group-186 .path2:before {
  content: "\e934";
  margin-left: -1.357421875em;
  color: rgb(1, 82, 150);
}

.icon-Group-186 .path3:before {
  content: "\e935";
  margin-left: -1.357421875em;
  color: rgb(0, 0, 0);
}

.icon-Group-186 .path4:before {
  content: "\e936";
  margin-left: -1.357421875em;
  color: rgb(0, 0, 0);
}

.icon-fi_10252078:before {
  content: "\e91f";
  color: var(--label-color);
}

.icon-XMLID_298:before {
  content: "\e91e";
  color: var(--heading-color);
}

.icon-Frame-1000008292 .path1:before {
  content: "\e91c";
  color: rgb(241, 250, 255);
}

.icon-Frame-1000008292 .path2:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(1, 82, 150);
}

.icon-Component-2-1:before {
  content: "\e900";
  color: $primaryWhiteColor;
}

.icon-Component-2:before {
  content: "\e901";
  color: $primaryWhiteColor;
}

.icon-Component-3:before {
  content: "\e902";
  color: $primaryWhiteColor;
}

.icon-fi_702814:before {
  content: "\e903";
  color: var(--heading-color);
}

.icon-fi_1946488:before {
  content: "\e904";
  color: var(--heading-color);
}

.icon-fi_2658057:before {
  content: "\e905";
  color: var(--label-color);
}

.icon-fi_3018421:before {
  content: "\e906";
  color: var(--label-color);
}

.icon-fi_3913243:before {
  content: "\e907";
  color: var(--heading-color);
}

.icon-fi_5585332:before {
  content: "\e908";
  color: var(--label-color);
}

.icon-fi_5818555:before {
  content: "\e909";
  color: var(--label-color);
}

.icon-fi_9241039:before {
  content: "\e90a";
  color: var(--label-color);
}

.icon-fi_9503363:before {
  content: "\e90b";
  color: var(--heading-color);
}

.icon-fi_9720850:before {
  content: "\e90c";
  color: var(--heading-color);
}

.icon-fi_15320132:before {
  content: "\e90d";
  color: var(--heading-color);
}

.icon-Frame-1000008281-1 .path1:before {
  content: "\e90e";
  color: rgb(55, 125, 255);
  opacity: 0.16;
}

.icon-Frame-1000008281-1 .path2:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(1, 82, 150);
}

.icon-Frame-1000008281-2 .path1:before {
  content: "\e910";
  color: rgb(55, 125, 255);
  opacity: 0.16;
}

.icon-Frame-1000008281-2 .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(1, 82, 150);
}

.icon-Frame-1000008281-2 .path3:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(1, 82, 150);
}

.icon-Frame-1000008281 .path1:before {
  content: "\e913";
  color: rgb(55, 125, 255);
  opacity: 0.16;
}

.icon-Frame-1000008281 .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(1, 82, 150);
}

.icon-Frame-1000008281 .path3:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(1, 82, 150);
}

.icon-Frame-1000008281 .path4:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(1, 82, 150);
}

.icon-Frame-1000008281 .path5:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(1, 82, 150);
}

.icon-Frame-1000008281 .path6:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(1, 82, 150);
}

.icon-Frame-1000008281 .path7:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(1, 82, 150);
}

.icon-Frame-1000008281 .path8:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(1, 82, 150);
}

.icon-Social-Icons:before {
  content: "\e91b";
  color: $primaryWhiteColor;
}