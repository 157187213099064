.p-inputgroup-addon {
    background: $inputGroupBg;
    color: $inputGroupTextColor;
    border-top: $inputBorder;
    border-left: $inputBorder;
    border-bottom: $inputBorder;
    padding: $inputPadding;
    min-width: $inputGroupAddOnMinWidth;
    padding-right: 0;

    &:last-child {
        border-right: $inputBorder;
    }
}

.p-inputgroup {
    > .p-component,
    > .p-inputwrapper > .p-inputtext,
    > .p-float-label > .p-component {
        border-radius: 0;
        margin: 0;

        + .p-inputgroup-addon {
            border-left: 0 none;
        }

        &:focus {
            z-index: 1;

            ~ label {
                z-index: 1;
            }
        }
    }
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
}

.p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}

.p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}

.p-fluid {
    .p-inputgroup {
        .p-button {
            width: auto;

            &.p-button-icon-only {
                width: $buttonIconOnlyWidth;
            }
        }
    }
}

.search-consumer {
    .p-inputgroup {
        gap: 10px;

        .p-inputtext {
            border: 1px solid #D8DBE3;
            background-color: #FFFFFF;
            border-radius: 10px;
            padding: 8px 15px;
            font-size: 0.875em;
            font-weight: 400;
            line-height: 1.5;
            color: #000;

            &::placeholder {
                color: #959494;
            }
        }

        .p-button {
            background-color: var(--heading-color);
            border-radius: 9px;
            width: 44px;
            height: 36px;
        }
    }
}


.add-update-doc, .approve-feasibility-popup {
    .p-inputgroup {
       gap: 10px;
    }
}