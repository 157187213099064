.p-inputtext {
    font-family: 'poppins';
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 0.875em;
    color: var(--text-color);
    background: var(--bg-color-block);
    padding: $inputPadding;
    border: 1px solid var(--border-input);
    transition: $formElementTransition;
    appearance: none;
    border-radius: $borderRadius;

    &:hover {
        border-color: var(--heading-color);
    }

    &:focus {
        outline: none;
    }

    &.ng-dirty.ng-invalid {
        @include invalid-input();
    }

    &.p-filled{
        background: var(--bg-color-block);
        // &:hover{
        //     border: $inputBorder;
        // }
    }

    &.p-variant-filled {
        background-color: $inputFilledBg;

        &:enabled:hover {
            background-color: $inputFilledHoverBg;
        }

        &:enabled:focus {
            background-color: $inputFilledFocusBg;
        }
    }

    &.p-inputtext-sm {
        @include scaledFontSize($inputTextFontSize, $scaleSM);
        @include scaledPadding($inputPadding, $scaleSM);
    }

    &.p-inputtext-lg {
        @include scaledFontSize($inputTextFontSize, $scaleLG);
        @include scaledPadding($inputPadding, $scaleLG);
    }
}

.p-float-label>label {
    left: nth($inputPadding, 2);
    color: $inputPlaceholderTextColor;
    transition-duration: $transitionDuration;
}

.p-float-label>.ng-invalid.ng-dirty+label {
    color: $inputErrorBorderColor;
}

.p-input-icon-left>.p-icon-wrapper.p-icon,
.p-input-icon-left>i:first-of-type {
    left: nth($inputPadding, 2);
    color: var(--text-color);
}

.p-input-icon-left>.p-inputtext {
    padding-left: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

.p-input-icon-left.p-float-label>label {
    left: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

.p-input-icon-right>.p-icon-wrapper,
.p-input-icon-right>i:last-of-type {
    right: nth($inputPadding, 2);
    color: var(--text-color);
}

.p-input-icon-right>.p-inputtext {
    padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

.p-icon-field-left>.p-inputtext {
    padding-left: nth($inputPadding, 2) * 2 + $primeIconFontSize !important;
}

.p-icon-field-left.p-float-label>label {
    left: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

.p-icon-field-right>.p-inputtext {
    padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

@include placeholder {
    color: $inputPlaceholderTextColor;
    font-size: 1em;
}

.p-input-filled {
    .p-inputtext {
        background-color: $inputFilledBg;

        &:enabled:hover {
            background-color: $inputFilledHoverBg;
        }

        &:enabled:focus {
            background-color: $inputFilledFocusBg;
        }
    }
}

.p-inputtext-sm {
    .p-inputtext {
        @include scaledFontSize($inputTextFontSize, $scaleSM);
        @include scaledPadding($inputPadding, $scaleSM);
    }
}

.p-inputtext-lg {
    .p-inputtext {
        @include scaledFontSize($inputTextFontSize, $scaleLG);
        @include scaledPadding($inputPadding, $scaleLG);
    }
}

.complete-profile-dialog,
.consumer-login,
.consumer-app-popup,
.submit-detail,
.submit-details-popup {
    .p-inputtext {
        padding: 12px;
        font-size: 0.875em;
        font-weight: 500;
        line-height: 1.5;
        border: 1px solid;
        border-radius: 8px;
        border-color: var(--form-input-border) !important;

        @media screen and (max-width: 1400px) {
            padding: 10px;
            font-size: 0.75em;
        }

        &::placeholder {
            color: $srt-neutral-2300;
            font-weight: 400;
            font-size: 1em;

            @media screen and (max-width: 1400px) {
                font-size: 0.75em;
            }
        }
    }
}

.complete-profile-dialog .disabled {
    .p-inputtext {
        background-color: #eee;
    }
}

.consumer-login .p-inputtext {
    border-radius: 0;
    border: 0;
    border-left: 1px solid var(--form-input-border);
    background-color: transparent;
}

.new-app-form {
    .p-inputtext {
        width: 100%;
    }
}

.bank-d-labels {
    .p-inputtext {
        width: 100%;
    }
}

.submit-detail {
    .p-inputtext {
        width: 100%;

        &::placeholder {
            color: $srt-black;
        }

        &:disabled {
            background-color: $srt-neutral-5400;
            opacity: 1;
        }
    }
}

.app-tab {
    .p-inputtext {
        font-size: 0.75em;
        border-radius: unset;
        min-width: 228px;
    }
}

.app-tab-2 {
    .p-inputtext {
        font-size: 0.75em;
        border-radius: unset;
        min-width: 228px;
    }
}

.redem-subsidy {
    .p-inputtextarea {
        padding: 12px;
        font-size: 0.875em;
        font-weight: 400;
        line-height: 1.5;
        color: $srt-neutral-2500;
        border: 1px solid;
        border-radius: 8px;
        border-color: $srt-neutral-2400 !important;
        width: 100%;

        @media screen and (max-width: 1400px) {
            padding: 10px;
            font-size: 0.75em;
        }
    }
}

.p-profile-file {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: none !important;
    margin-right: -6px;
    padding-bottom: 8px !important;
}
.remarks{
    .p-inputtext{
        width: 100%;
        margin-top: 9px;
    }
    .p-inputtext:hover{
        border-color: var(--form-input-border);
    }
}

.view-app-dialog{
    .p-inputtext{
        border-radius: 0px;
    }
}

.add-district-officer {
    .p-inputtext {
        font-size: 0.875em;
        font-weight: 400;
        line-height: 1.5;
        color: #000;
        padding: 10px;
        border: 1px solid var(--form-input-border);
    }
}
.rp-form-group {
    margin-bottom: 20px;

    label {
        display: block;
        font-size: 0.875em;
        font-weight: 500;
        color: #2c63b8;
        margin-bottom: 6px;
    }

    input {
        font-family: 'poppins';
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: $inputTextFontSize;
        color: $inputTextColor;
        background: $inputBg;
        padding: $inputPadding;
        border: $inputBorder;
        transition: $formElementTransition;
        appearance: none;
        border-radius: $borderRadius;
        width: 100%;

        &:hover {
            border-color: $primaryColor;
        }

        &:focus {
            outline: none;
        }

    }

}
.form-group {
    label {
        display: block;
        font-size: 0.875em;
        font-weight: 500;
        color: #2c63b8;
    }
}
.rp-remarks-textarea {
    width: 100%;
    padding: 10px;
    resize: unset;
    border-radius: 6px;
    border-color: #D0D5DD;
    font-family: 'Poppins';
    font-weight: 400;
    &:hover {
        border-color: $primaryColor;
    }

    &:focus {
        outline: none;
    }

    &.ng-dirty.ng-invalid {
        @include invalid-input();
    }

    &.p-filled{
        background: var(--bg-color-block);
        &:hover{
            border: $inputBorder;
        }
    }
}