.p-dialog {
    border-radius: $borderRadius;
    box-shadow: $overlayContainerShadow;
    border: $overlayContentBorder;

    @media only screen and (max-width: 768px) {
        margin: 0 5%;
        width: 90% !important;
    }

    .p-dialog-header {
        border-bottom: $dialogHeaderBorder;
        background: var(--bg-color);
        color: var(--text-color);
        padding: $dialogHeaderPadding;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;

        .p-dialog-title {
            font-weight: $dialogHeaderFontWeight;
            font-size: $dialogHeaderFontSize;
            color: var(--heading-color);
        }

        .p-dialog-header-icon {
            @include action-icon();
            margin-right: $inlineSpacing;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .p-dialog-content {
        background: var(--bg-color-block);
        color: var(--text-color);
        padding: $dialogContentPadding;

        &:last-of-type {
            border-bottom-right-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
        }
    }

    .p-dialog-footer {
        border-top: $dialogFooterBorder;
        background: var(--bg-color);
        color: $panelFooterTextColor;
        padding: $dialogFooterPadding;
        text-align: right;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;

        button {
            margin: 0 $inlineSpacing 0 0;
            width: auto;
        }
    }

    &.p-confirm-dialog {
        .p-confirm-dialog-icon {
            font-size: $primeIconFontSize * 2;

            &.p-icon {
                width: $primeIconFontSize * 2;
                height: $primeIconFontSize * 2;
            }
        }

        .p-confirm-dialog-message {
            margin-left: $inlineSpacing * 2;
        }
    }
}

.consumer-app-popup {
    .p-dialog {
        .p-dialog-header {

            @media screen and (max-width: 1400px) {
                padding: 20px;
            }

            @media screen and (max-width: 1200px) {
                padding: 18px;
            }

            @media screen and (max-width: 992px) {
                padding: 10px 16px;
            }

            .p-dialog-title {
                font-size: 1.5em;
                font-weight: 600;
                line-height: 1.5;
                color: $srt-neutral-5200;

                @media screen and (max-width: 1400px) {
                    font-size: 1em;
                }

                @media screen and (max-width: 1200px) {
                    font-size: 1.125em;
                }

                @media screen and (max-width: 992px) {
                   font-size: 1em;
                }
            }

            .p-dialog-header-icon {
                color: $srt-neutral-5100;

                &:hover {
                    background-color: transparent;
                }
            }
        }

        .p-dialog-content {

            @media screen and (max-width: 1400px) {
                padding: 20px;
            }

            @media screen and (max-width: 1200px) {
                padding: 18px;
            }

            @media screen and (max-width: 992px) {
                padding: 16px;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
            }

            .form-wrapper {
                // display: grid;
                gap: 9px;

                label {
                    display: block;
                    font-size: 0.875em;
                    font-weight: 500;
                    line-height: 1.5;
                    color: $srt-black;
                    margin-bottom: 8px;

                    @media screen and (max-width: 1400px) {
                        font-size: 0.75em;
                        margin-bottom: 5px;
                    }
                }

                .icon-info-circle {
                    font-size: 1.125em;

                    @media screen and (max-width: 1400px) {
                       font-size: 1em;
                    }

                    @media screen and (max-width: 1200px) {
                        font-size: 0.875em;
                    }
                }

                .icon-info-circle[data-tip]:hover::after {
                    content: attr(data-tip);
                    position: absolute;
                    background-color: #000;
                    color: #fff;
                    font-size: 0.625em;
                    width: 140px;
                    padding: 10px;
                    border-radius: 10px;
                    z-index: 1;
                    font-family: 'Poppins';
                    line-height: 1.2;
                    margin-top: -8px;
                }

                .error {
                    color: red;
                    font-size: 0.75em;
                    font-weight: 500;
                    margin-top: 6px;
                    display: block;
                }

                .consumer-label {
                    background: rgba(226, 226, 234, 0.5);
                    border: 1px solid $srt-neutral-2400;
                    border-radius: 16px;
                    min-height: 269px;
                    font-size: 0.875em;
                    font-weight: 400;
                    line-height: 1.4;
                    color: $srt-neutral-2300;
                    padding: 20px !important;

                    @media screen and (max-width: 1400px) {
                        font-size: 0.75em;
                        padding: 18px !important;
                    }

                    .grid {
                        row-gap: 16px;

                        @media screen and (max-width: 1400px) {
                            row-gap: 14px;
                        }

                        @media screen and (max-width: 1200px) {
                            row-gap: 12px;
                        }
                    }

                    label {
                        font-size: 0.875em;
                        font-weight: 400;
                        line-height: 1.5;
                        color: $srt-neutral-2500;

                        @media screen and (max-width: 1400px) {
                            font-size: 0.75em;
                        }
                    }

                    span,
                    h4 {
                       font-size: 1em;
                        font-weight: 600;
                        line-height: 1.5;
                        color: $srt-black;

                        @media screen and (max-width: 1400px) {
                            font-size: 0.875em;
                        }

                        @media screen and (max-width: 1200px) {
                            font-size: 0.75em;
                        }
                    }

                    h4 {
                        margin-bottom: 12px !important;
                    }
                }
            }
        }
    }
}

.submit-details-popup {
    .p-dialog {
        max-width: 600px;
        width: 100%;

        .p-dialog-header {
            font-size: 1.375em;
            font-weight: 600;
            line-height: 1.5;
            color: var(--heading-color);
            padding: 32px 32px 0 32px;
            border: unset;

            @media screen and (max-width: 1400px) {
                padding: 22px 22px 0 22px;
            }

            .p-dialog-header-icon {
                color: $srt-black;
                background-color: unset !important;
            }
        }

        .p-dialog-content {
            padding: 24px 32px 32px 32px;
            display: grid;
            gap: 28px;

            @media screen and (max-width: 1400px) {
                padding: 18px 22px 22px 22px;
                gap: 24px;
            }

            label {
                display: block;
                font-size: 0.875em;
                font-weight: 500;
                line-height: 1.5;
                color: $srt-black;
                margin-bottom: 8px;

                @media screen and (max-width: 1400px) {
                    font-size: 0.75em;
                    margin-bottom: 5px;
                }
            }

            .form-group {
                position: relative;

                .pi {
                    position: absolute;
                    right: 10px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: fit-content;
                    color: var(--heading-color);
                }
            }

            .selected-values {
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 4px;

                    li {
                        border: 1px solid $srt-neutral-2400;
                        border-radius: 26px;
                        background-color: $srt-neutral-5400;
                        font-size: 0.875em;
                        font-weight: 400;
                        line-height: 1.4;
                        color: $srt-black;
                        width: fit-content;
                        padding: 10px 20px;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        @media screen and (max-width: 1400px) {
                            font-size: 0.75em;
                            padding: 8px 16px;
                        }
                    }
                }
            }
        }
    }
}

.p-vendor-review-dialog {

    .p-dialog-header {
        padding: 0.75rem 1rem;
    }
}

.p-vendor-deatils-dialog {
    .p-dialog-content {
        overflow: visible;
        padding: 1.5rem;
        border-radius: 10px;

        @media screen and (max-width: 992px) {
            overflow: auto;
        }
    }
}

.p-states-dialog {
    .p-dialog-content {
        @media screen and (max-width: 992px) {
            overflow: auto;
        }

        overflow: visible;
    }
}

.p-product-master-dialog {
    .p-dialog {
        padding: 10px !important;

        .p-dialog-header {
            border-bottom: none !important;
            padding: 15px !important;

            .p-dialog-header-icons {
                top: 20px !important
            }
        }

        .p-dialog-content {
            padding: 0px !important;
        }
    }
}

.registered-vendor {
    .p-dialog {
        max-width: 460px;

        .p-dialog-header {
            display: none;
        }

        .p-dialog-content {
            border-radius: 16px 16px 0 0;
            padding: 30px 30px 0 30px;

            h2 {
                font-size: 1.25em !important;
                font-weight: 600 !important;
                line-height: 1.5;
                text-align: center;
                color: $srt-black !important;
                margin-bottom: 28px !important;

                @media screen and (max-width: 1400px) {
                    font-size: 1.125em !important;
                }

                @media screen and (max-width: 1200px) {
                    font-size: 1em !important;
                }

                @media screen and (max-width: 992px) {
                    font-size: 0.875em !important;
                }
            }
        }

        .p-dialog-footer {
            border-radius: 0 0 16px 16px;
            padding: 0 30px 30px 30px;
            border: 0;
            display: flex;
            justify-content: space-around;
            gap: 20px;

            button {
                width: 100%;
                margin: 0;
            }

            .p-confirm-dialog-reject {
                border-color: var(--form-input-border);
                color: #494949;
            }
        }
    }
}

.search-consumer {
    .p-dialog {
        max-width: 466px;
        width: 100%;

        .p-dialog-header {
            padding-top: 30px;
            justify-content: center;

            .p-dialog-title {
               font-size: 1em;
                font-weight: 600;
                line-height: 1.5;
                color: var(--heading-color);
                text-align: center;
            }

            .p-dialog-header-icons {
                position: absolute;
                top: 6px;
                right: 14px;

                .p-dialog-header-icon {
                    color: var(--heading-color);
                    width: 20px;
                    height: 20px;
                    background: #fff !important;
                }
            }
        }

        .p-dialog-content {
            display: grid;
            gap: 20px;

            label {
                font-size: 0.875em;
                font-weight: 500;
                line-height: 1.5;
                color: #344054;
            }

            .p-inputtext {
                padding: 10px 16px;
                font-size: 0.875em;
                font-weight: 500;
                line-height: 1.5;
                color: #000;
                border: 1px solid #D0D5DD;
                border-radius: 8px;

                &::placeholder {
                    color: #959494;
                }
            }
        }
    }
}

.p-profile-dialog {

    .p-dialog-header {
        justify-content: center;
        border: unset;
        position: relative;

        .p-dialog-header-icon {
            position: absolute;
            right: 25px;
        }

        .p-dialog-title {
            color: var(--heading-color);
            font-size: 2.25em;
            font-weight: 600;
            line-height: 1.5;
            text-align: center;
            margin: 0;

            @media screen and (max-width: 1400px) {
                font-size: 1.875em;
            }

            @media screen and (max-width: 1200px) {
                font-size: 1.5em;
            }

            @media screen and (max-width: 992px) {
                font-size: 1em;
            }

            @media screen and (max-width: 768px) {
                font-size: 1.125em;
            }

            @media screen and (max-width: 576px) {
               font-size: 1em;
            }
        }
    }
}

.view-app-dialog {
    .p-dialog .p-dialog-header {
        display: none;
    }

    .p-dialog .p-dialog-content:last-of-type {
        border-radius: 10px !important;
    }
}

.p-filter-details-dialog {
    .p-dialog-content {
        overflow: visible;
        padding: 1.5rem;
    }
}

.p-govt-build-dialog {
    .p-dialog-content {
        overflow: visible;
    }
}

.add-district-officer {
    .p-dialog {
        .p-dialog-header {
            padding: 0.5rem;
        }
    }
}

.reviewer-popup {
    .p-dialog {
        .p-dialog-header {
            padding: 1rem 1rem 0;
            border: 0;
        }

        .p-dialog-content {
            padding: 0 1rem;
        }

        .p-dialog-footer {
            padding: 0 1rem 1rem;
            border: 0;
        }
    }
}

.search-consumer-popup {
    .p-dialog {
        .p-dialog-content {
            label {
                font-size: 0.875em;
                font-weight: 500;
                line-height: 1.2;
                color: #344054;
            }

            p-inputnumber {
                flex: 1;

                .p-inputnumber {
                    width: 100%;

                    .p-inputnumber-input {
                        border: 1px solid #D0D5DD;
                        border-radius: 8px;
                        padding: 8px 16px;
                        font-size: 0.875em;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #000;

                        &::placeholder {
                            font-size: 1em;
                            color: #959494;
                        }
                    }
                }
            }

            .search-btn {
                .p-button {
                    .p-button-label {
                        font-size: 0.875em;
                    }
                }
            }
        }
    }
}

.brand-selection-vendor {
    .p-dialog {
        .p-dialog-header {
            justify-content: center;
            padding: 10px;

            .p-dialog-icon {
                position: absolute;
                top: 10px;
                right: 10px;
                color: var(--heading-color);
                cursor: pointer;
            }
        }

        .p-dialog-content {
            form {
                display: grid;
                gap: 10px;
            }

            p {
                font-size: 0.875em;
                font-weight: 500 !important;
                line-height: 1.5 !important;
                letter-spacing: 0.05em;
                color: var(--heading-color);
                margin: 0 !important;
                text-align: center;
            }

            .remark-textarea {
                resize: none;
                border-radius: 8px;
                width: 100%;
                font-size: 0.875em;
                font-weight: 400;
                line-height: 1.5;
                padding: 10px;
            }
        }
    }
}

.rp-dialog-small {
    .p-dialog {
        .p-dialog-header {
            justify-content: space-between;
        }

        .p-dialog-content {
            form {
                display: grid;
                gap: 10px;
            }

            p {
                text-align: left;
            }

        }
    }
}

approve-feasibility-popup {
    .p-dialog .p-dialog-content {
        overflow: visible;
    }
}

.custom-dailog {
    .p-dialog {
        .p-dialog-content {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }
}

.portal-popup {
    width: 70%;
    position: relative;
    z-index: 1;

    // &::before {
    //     content: " ";
    //     background-image: url('../../../images/2.0-blue-bg-01.svg');
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 586px;
    //     height: 550px;
    //     background-repeat: no-repeat;
    //     z-index: 0;
    // }

    // &::after {
    //     content: " ";
    //     background-image: url('../../../images/2.0-blue-bg-01.svg');
    //     position: absolute;
    //     bottom: 0;
    //     right: 0;
    //     width: 586px;
    //     height: 550px;
    //     background-repeat: no-repeat;
    //     z-index: 0;
    //     transform: rotate(180deg);
    // }

    @media only screen and (max-width: 767px) {
        width: 100%;
    }

    .p-dialog-header {
        border-bottom: none;
    }

    .p-dialog-content {
        padding: 0;
        background-image: url('../../../images/2.0-graphic.svg');
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: 50%;
    }
}