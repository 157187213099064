.p-timeline {
    .p-timeline-event-marker {
        border: $timelineEventMarkerBorder;
        border-radius: $timelineEventMarkerBorderRadius;
        width: $timelineEventMarkerWidth;
        height: $timelineEventMarkerHeight;
        background-color: $timelineEventMarkerBackground;
    }

    .p-timeline-event-connector {
        background-color: $timelineEventColor;
    }

    &.p-timeline-vertical {

        .p-timeline-event-opposite,
        .p-timeline-event-content {
            padding: $timelineVerticalEventContentPadding;
        }

        .p-timeline-event-connector {
            width: $timelineEventConnectorSize;
        }

        .p-timeline-event-opposite {
            display: none;
        }

        .p-timeline-event-content {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

    &.p-timeline-horizontal {

        .p-timeline-event-opposite,
        .p-timeline-event-content {
            padding: $timelineHorizontalEventContentPadding;
        }

        .p-timeline-event-connector {
            height: $timelineEventConnectorSize;
        }
    }
}

.app-journey {
    .p-timeline {
        gap: 12px;
        border: 1px solid $srt-neutral-2400;
        background-color: $srt-white;
        padding: 16px;
        border-radius: 8px;
        margin-top: 14px;


        @media screen and (max-width: 1200px) {
            gap: 0;
        }

        @media screen and (max-width: 992px) {
            flex-direction: column;
        }

        .p-timeline-event {

            @media screen and (max-width: 992px) {
                flex-direction: row;
            }

            .p-timeline-event-opposite {
                display: none;
            }

            .p-timeline-event-separator {
                .status {
                    width: 14px;
                    height: 14px;
                    border: 2px solid gray;
                    border-radius: 100%;
                }

                .COMPLETED {
                    border: 2px solid green;
                }

                .IN_PROGRESS {
                    border: 2px solid orange;
                }

                .PENDING {
                    border: 2px solid gray;
                }

                @media screen and (max-width: 992px) {
                    flex-direction: column;
                    margin-right: 20px;
                }

                .p-timeline-event-marker {
                    border: 2px solid $srt-neutral-2900;
                    width: 14px;
                    height: 14px;
                }

                .p-timeline-event-connector {
                    border-bottom: 2px dashed $srt-neutral-3700;
                    background-color: unset;
                    height: unset;
                    width: calc(100% - 14px);

                    @media screen and (max-width: 992px) {
                        border-right: 2px dashed $srt-neutral-3700;
                        background-color: unset;
                        height: unset;
                        width: unset;
                    }
                }
            }

            .p-timeline-event-content {
                padding: 0;
                min-width: 87px;

                .timeline-event {
                    &.step1 h3 {
                        background-color: $srt-neutral-4000;
                    }

                    &.step2 h3 {
                        background-color: $srt-neutral-4100;
                    }

                    &.step3 h3 {
                        background-color: $srt-neutral-4200;
                    }

                    &.step4 h3 {
                        background-color: $srt-neutral-3800;
                    }

                    &.step5 h3 {
                        background-color: $srt-neutral-4300;
                    }

                    &.step6 h3 {
                        background-color: $srt-neutral-4400;
                    }

                    &.step7 h3 {
                        background-color: $srt-neutral-4500;
                        ;
                    }

                    &.step8 h3 {
                        background-color: $srt-neutral-4600;
                    }

                    &.step9 h3 {
                        background-color: $srt-neutral-4700;
                    }

                    &.step10 h3 {
                        background-color: $srt-neutral-4800;
                    }

                    h3 {
                        border-radius: 100px;
                        padding: 2px 12px !important;
                        font-size: 0.875em;
                        font-weight: 500;
                        line-height: 1.5;
                        color: $srt-neutral-3900;
                        display: inline-block;
                        margin-top: 14px !important;

                        @media screen and (max-width: 1200px) {
                            font-size: 0.75em;
                        }

                        @media screen and (max-width: 992px) {
                            margin-top: 0 !important;
                        }
                    }

                    h4 {
                        font-size: 1em;
                        font-weight: 500;
                        line-height: 1.5;
                        color: $srt-black;
                        margin: 0;

                        @media screen and (max-width: 1400px) {
                            font-size: 1.125em;
                        }
                    }

                    p {
                        font-size: 0.875em;
                        font-weight: 500 !important;
                        line-height: 1.3 !important;
                        color: $srt-black;
                        margin: 14px 0 0 !important;

                        @media screen and (max-width: 1400px) {
                            font-size: 0.75em;
                        }

                        @media screen and (max-width: 992px) {
                            margin: 7px 0 0 !important;
                        }
                    }
                }
            }
        }
    }
}

.steps-detail {
    background-color: $srt-neutral-3000;
    border: 1px solid $srt-neutral-2400;
    border-radius: 24px;
    padding: 24px;

    .p-timeline {
        background-color: unset;
        border: 0;
        border-radius: unset;
        padding: 0;
        max-width: 800px;
        margin: 0 0 0 auto;

        &.p-timeline-horizontal .p-timeline-event:last-child {
            flex: 1;
        }

        .p-timeline-event {
            .p-timeline-event-separator {
                .p-timeline-event-marker {
                    border-width: 3px;

                    &.past {
                        border-color: $srt-neutral-5500;
                    }

                    &.present {
                        border-color: $srt-neutral-3500;
                    }

                    &.future {
                        border-color: $srt-neutral-2300;
                    }
                }
            }

            .p-timeline-event-content {
                left: -50%;
                position: relative;
                width: 100%;
                text-align: center;
            }
        }
    }
}

.app-track-timeline {
    .p-timeline-bottom .p-timeline-event {
        flex-direction: column;
    }
}

.overview-timeline {
    .classOne .p-timeline-event-connector {
        width: 168px !important;
    }

    h3 {
        font-size: 0.938 !important;
    }
}

.feasibility-tab {
    .app-journey .p-timeline {
        margin: 0px !important;
        padding: 10px !important;
    }

}

.classone {
    .p-timeline.p-timeline-vertical {
        .completed-step+.p-timeline-event-connector {
            background-color: #1FA822;
        }
    }
}

.classone {
    .p-timeline.p-timeline-horizontal {
        overflow: auto;
        .p-timeline-event {
            display: block;
        }
        .p-timeline-event-opposite {
            color: #959595;
            font-size: 0.875em;
	        text-align: left;
        }

        .p-timeline-event-connector {
            margin: 5px;
            width: 3px;
            border-radius: 2px;
        }

        .completed-step+.p-timeline-event-connector {
            background-color: #1FA822;
        }

                .p-timeline-event-content {
        
                    display: flex;
                    flex-direction: column;
                    flex: 3;
                    gap: 15px;
                    margin-top: 8px;
                    color: #959595;
                    font-size: 0.875em;
                    padding: 0px 8px 15px 0px;
                }
                }

    .completed-chip {
        font-size: 0.625em;
        color: #1FA822;
        background: #DDFFDD;
        width: 69px;
        padding: 5px;
        border-radius: 20px;
        border: 1px solid #1FA822;
        text-align: center;
    }

    .inprog-chip {
        font-size: 0.625em;
        color: #3C8EE3;
        background: #EEF6FF;
        width: 69px;
        padding: 5px;
        border-radius: 20px;
        border: 1px solid #79B9FC;
        white-space: nowrap;
        text-align: center;
    }

    .pending-chip {
        font-size: 0.625em;
        color: #494949;
        background: #F6F6F6;
        width: 69px;
        padding: 5px;
        border-radius: 20px;
        border: 1px solid #D5D5D5;
        text-align: center;
    }
}

.timeline-container {
    .p-timeline.p-timeline-horizontal {
        .p-timeline-event-connector {
            height: unset;
            position: absolute;
            border-bottom: 2px dashed #AFAFAF;
            background-color: unset;
        }

        .p-timeline-event {
            align-items: center;

            &:last-child {
                flex: 1;
            }
        }

        .p-timeline-event-opposite {
            display: none;
        }

        .p-timeline-event-content {
            padding-bottom: 0;
        }
    }
}
