.p-inputotp {
    display: flex;
    align-items: center;
    gap: $inlineSpacing;
}

.p-inputotp-input {
    text-align: center;
    width: 2 * nth($inputPadding, 2) + 1rem;
}

.complete-profile-dialog, .consumer-login, .consumer-profile, .admin-login-wrap, .discom-login-wrap {
    .p-inputotp {
        gap: 16px;

        @media screen and (max-width: 1400px) {
            gap: 14px;
        }
        
        @media screen and (max-width: 1200px) {
            gap: 10px;
        }

        .p-inputotp-input {
            width: 46px;
            height: 46px;
            padding: 0;
            font-size: 1em;
            color: $srt-neutral-2500;
            border-radius: 12px;
            border: 1px solid var(--border-input);

            @media screen and (max-width: 1400px) {
                width: 39px;
                height: 39px;
                font-size: 1.125em;
            }
        }
    }
}

.vendor-login{
    .p-inputotp-input {
        width: 46px;
        height: 46px;
        padding: 0;
        font-size: 1em;
        color: $srt-neutral-2500;
        border-radius: 12px;
        border: 1px solid $srt-neutral-2400;

        @media screen and (max-width: 1400px) {
            width: 39px;
            height: 39px;
            font-size: 1.125em;
        }
    }
}
