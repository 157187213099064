@use 'sass:math';

.p-inputswitch {
    width: $inputSwitchWidth;
    height: $inputSwitchHeight;

    .p-inputswitch-slider {
        background: #3C8EE3;
        transition: $formElementTransition;
        border-radius: $inputSwitchBorderRadius;

        &:before {
            background: $inputSwitchHandleOffBg;
            width: $inputSwitchHandleWidth;
            height: $inputSwitchHandleHeight;
            left: $inputSwitchSliderPadding;
            margin-top: math.div(-1 * $inputSwitchHandleHeight, 2);
            border-radius: $inputSwitchHandleBorderRadius;
            transition-duration: $transitionDuration;
        }
    }

    &.p-inputswitch-checked {
        .p-inputswitch-slider:before {
            transform: translateX($inputSwitchHandleWidth);
        }
    } 

    &.p-inputswitch-checked {
        .p-inputswitch-slider {
            background: $inputSwitchSliderOnBg;

            &:before {
                background: $inputSwitchHandleOnBg;
             }
        }
    }
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch > .p-inputswitch-slider {
    @include invalid-input();
}

.p-admin-switch{
    .p-inputswitch-slider{
        background: red;
        &::before{
            content: "\e90b";
            font-family: primeicons;
            font-size: 0.75em;
            color:red;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &.p-inputswitch-checked {
        .p-inputswitch-slider {
            background:green;
            &::before{
                content: "\e909";
                font-family: primeicons;
                color:green;
            }
        }
    }
}

.discom-dashboard {
    .p-inputswitch {
        width: 40px;
        height: 20px;

        &.p-inputswitch-checked {
            background: #fff;

            .p-inputswitch-slider {
                background: var(--heading-color);

                &:before {
                    background: #fff;
                }
            }
        }

        .p-inputswitch-slider {
            border: 1px solid var(--heading-color);
            background: #fff;

            &:before {
                background: var(--heading-color);
                width: 15px;
                height: 15px;
                left: 2px;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
}