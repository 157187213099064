.p-button {
    color: var(--button-color);
    background: var(--heading-color);
    border: 1px solid var(--heading-color);
    padding: $buttonPadding;
    font-size: 0.875em;
    transition: $formElementTransition;
    border-radius: $borderRadius;
    outline-color: transparent;

    &:not(:disabled):active {
        background: var(--heading-color);
        color: var(--button-color);
        border-color: var(--heading-color);
    }

    &.p-button-outlined {
        background-color: transparent;
        color: var(--heading-color);
        border: $outlinedButtonBorder;

        &:not(:disabled):hover {
            background: rgba(var(--heading-color), $textButtonHoverBgOpacity);
            color: var(--heading-color);
            border: $outlinedButtonBorder;
        }

        &:not(:disabled):active {
            background: rgba(var(--heading-color), $textButtonActiveBgOpacity);
            color: var(--heading-color);
            border: $outlinedButtonBorder;
        }

        &.p-button-plain {
            color: $plainButtonTextColor;
            border-color: $plainButtonTextColor;

            &:not(:disabled):hover {
                background: $plainButtonHoverBgColor;
                color: $plainButtonTextColor;
            }

            &:not(:disabled):active {
                background: $plainButtonActiveBgColor;
                color: $plainButtonTextColor;
            }
        }
    }

    &.p-blue-outline{
        border:1px solid var(--heading-color);
        color: var(--heading-color);
        &:not(:disabled):hover{
            background-color: $primaryWhiteColor;
            border:1px solid $primaryWhiteColor;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: var(--heading-color);
        border-color: transparent;

        &:not(:disabled):hover {
            background: rgba(var(--heading-color), $textButtonHoverBgOpacity);
            color: var(--heading-color);
            border-color: transparent;
        }

        &:not(:disabled):active {
            background: rgba(var(--heading-color), $textButtonActiveBgOpacity);
            color: var(--heading-color);
            border-color: transparent;
        }

        &.p-button-plain {
            color: $plainButtonTextColor;

            &:not(:disabled):hover {
                background: $plainButtonHoverBgColor;
                color: $plainButtonTextColor;
            }

            &:not(:disabled):active {
                background: $plainButtonActiveBgColor;
                color: $plainButtonTextColor;
            }
        }
    }

    &:focus-visible {
        @include focused();
    }

    .p-button-label {
        transition-duration: $transitionDuration;
    }

    .p-button-icon-left {
        margin-right: $inlineSpacing;
    }

    .p-button-icon-right {
        margin-left: $inlineSpacing;
    }

    .p-button-icon-bottom {
        margin-top: $inlineSpacing;
    }

    .p-button-icon-top {
        margin-bottom: $inlineSpacing;
    }

    .p-badge {
        margin-left: $inlineSpacing;
        min-width: $fontSize;
        height: $fontSize;
        line-height: $fontSize;
        color: var(--heading-color);
        background-color: $buttonTextColor;
    }

    &.p-button-raised {
        box-shadow: $raisedButtonShadow;
    }

    &.p-button-rounded {
        border-radius: $roundedButtonBorderRadius;
    }

    &.p-button-icon-only {
        width: $buttonIconOnlyWidth;
        padding: $buttonIconOnlyPadding;

        .p-button-icon-left,
        .p-button-icon-right {
            margin: 0;
        }

        &.p-button-rounded {
            border-radius: 50%;
            height: $buttonIconOnlyWidth;
        }       
    }

    &.p-button-sm {
        @include scaledFontSize($fontSize, $scaleSM);
        @include scaledPadding($buttonPadding, $scaleSM);

        .p-button-icon {
            @include scaledFontSize($primeIconFontSize, $scaleSM);
        }
    }

    &.p-button-lg {
        @include scaledFontSize($fontSize, $scaleLG);
        @include scaledPadding($buttonPadding, $scaleLG);

        .p-button-icon {
            @include scaledFontSize($primeIconFontSize, $scaleLG);
        }
    }

    &.p-button-loading-label-only {
        .p-button-label {
            margin-left: $inlineSpacing;
        }

        .p-button-loading-icon {
            margin-right: 0;
        }
    }
}

.p-fluid {
    .p-button {
        width: 100%;
    }

    .p-button-icon-only {
        width: $buttonIconOnlyWidth;
    }

    .p-button-group {
        display: flex;

        .p-button {
            flex: 1;
        }
    }
}

.p-button.p-button-secondary,
.p-button-group.p-button-secondary>.p-button,
.p-splitbutton.p-button-secondary>.p-button {
    color: $secondaryButtonTextColor;
    background: $secondaryButtonBg;
    border: $secondaryButtonBorder;

    &:not(:disabled):hover {
        background: $secondaryButtonHoverBg;
        color: $secondaryButtonTextHoverColor;
        border-color: $secondaryButtonHoverBorderColor;
    }

    &:not(:disabled):focus {
        box-shadow: $secondaryButtonFocusShadow;
    }

    &:not(:disabled):active {
        background: $secondaryButtonActiveBg;
        color: $secondaryButtonTextActiveColor;
        border-color: $secondaryButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $secondaryButtonBg;
        border: $outlinedButtonBorder;

        &:not(:disabled):hover {
            background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
            color: $secondaryButtonBg;
            border: $outlinedButtonBorder;
        }

        &:not(:disabled):active {
            background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
            color: $secondaryButtonBg;
            border: $outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $secondaryButtonBg;
        border-color: transparent;

        &:not(:disabled):hover {
            background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
            border-color: transparent;
            color: $secondaryButtonBg;
        }

        &:not(:disabled):active {
            background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
            border-color: transparent;
            color: $secondaryButtonBg;
        }
    }
}

.p-button.p-button-info,
.p-button-group.p-button-info>.p-button,
.p-splitbutton.p-button-info>.p-button {
    color: $infoButtonTextColor;
    background: $infoButtonBg;
    border: $infoButtonBorder;

    &:not(:disabled):hover {
        background: $infoButtonHoverBg;
        color: $infoButtonTextHoverColor;
        border-color: $infoButtonHoverBorderColor;
    }

    &:not(:disabled):focus {
        box-shadow: $infoButtonFocusShadow;
    }

    &:not(:disabled):active {
        background: $infoButtonActiveBg;
        color: $infoButtonTextActiveColor;
        border-color: $infoButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $infoButtonBg;
        border: $outlinedButtonBorder;

        &:not(:disabled):hover {
            background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
            color: $infoButtonBg;
            border: $outlinedButtonBorder;
        }

        &:not(:disabled):active {
            background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
            color: $infoButtonBg;
            border: $outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $infoButtonBg;
        border-color: transparent;

        &:not(:disabled):hover {
            background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
            border-color: transparent;
            color: $infoButtonBg;
        }

        &:not(:disabled):active {
            background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
            border-color: transparent;
            color: $infoButtonBg;
        }
    }
}

.p-button.p-button-success,
.p-button-group.p-button-success>.p-button,
.p-splitbutton.p-button-success>.p-button {
    color: $successButtonTextColor;
    background: $successButtonBg;
    border: $successButtonBorder;

    &:not(:disabled):hover {
        background: $successButtonHoverBg;
        color: $successButtonTextHoverColor;
        border-color: $successButtonHoverBorderColor;
    }

    &:not(:disabled):focus {
        box-shadow: $successButtonFocusShadow;
    }

    &:not(:disabled):active {
        background: $successButtonActiveBg;
        color: $successButtonTextActiveColor;
        border-color: $successButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $successButtonBg;
        border: $outlinedButtonBorder;

        &:not(:disabled):hover {
            background: rgba($successButtonBg, $textButtonHoverBgOpacity);
            color: $successButtonBg;
            border: $outlinedButtonBorder;
        }

        &:not(:disabled):active {
            background: rgba($successButtonBg, $textButtonActiveBgOpacity);
            color: $successButtonBg;
            border: $outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $successButtonBg;
        border-color: transparent;

        &:not(:disabled):hover {
            background: rgba($successButtonBg, $textButtonHoverBgOpacity);
            border-color: transparent;
            color: $successButtonBg;
        }

        &:not(:disabled):active {
            background: rgba($successButtonBg, $textButtonActiveBgOpacity);
            border-color: transparent;
            color: $successButtonBg;
        }
    }
}

.p-button.p-button-warning,
.p-button-group.p-button-warning>.p-button,
.p-splitbutton.p-button-warning>.p-button {
    color: $warningButtonTextColor;
    background: $warningButtonBg;
    border: $warningButtonBorder;

    &:not(:disabled):hover {
        background: $warningButtonHoverBg;
        color: $warningButtonTextHoverColor;
        border-color: $warningButtonHoverBorderColor;
    }

    &:not(:disabled):focus {
        box-shadow: $warningButtonFocusShadow;
    }

    &:not(:disabled):active {
        background: $warningButtonActiveBg;
        color: $warningButtonTextActiveColor;
        border-color: $warningButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $warningButtonBg;
        border: $outlinedButtonBorder;

        &:not(:disabled):hover {
            background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
            color: $warningButtonBg;
            border: $outlinedButtonBorder;
        }

        &:not(:disabled):active {
            background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
            color: $warningButtonBg;
            border: $outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $warningButtonBg;
        border-color: transparent;

        &:not(:disabled):hover {
            background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
            border-color: transparent;
            color: $warningButtonBg;
        }

        &:not(:disabled):active {
            background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
            border-color: transparent;
            color: $warningButtonBg;
        }
    }
}

.p-button.p-button-help,
.p-button-group.p-button-help>.p-button,
.p-splitbutton.p-button-help>.p-button {
    color: $helpButtonTextColor;
    background: $helpButtonBg;
    border: $helpButtonBorder;

    &:not(:disabled):hover {
        background: $helpButtonHoverBg;
        color: $helpButtonTextHoverColor;
        border-color: $helpButtonHoverBorderColor;
    }

    &:not(:disabled):focus {
        box-shadow: $helpButtonFocusShadow;
    }

    &:not(:disabled):active {
        background: $helpButtonActiveBg;
        color: $helpButtonTextActiveColor;
        border-color: $helpButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $helpButtonBg;
        border: $outlinedButtonBorder;

        &:not(:disabled):hover {
            background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
            color: $helpButtonBg;
            border: $outlinedButtonBorder;
        }

        &:not(:disabled):active {
            background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
            color: $helpButtonBg;
            border: $outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $helpButtonBg;
        border-color: transparent;

        &:not(:disabled):hover {
            background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
            border-color: transparent;
            color: $helpButtonBg;
        }

        &:not(:disabled):active {
            background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
            border-color: transparent;
            color: $helpButtonBg;
        }
    }
}

.p-button.p-button-danger,
.p-button-group.p-button-danger>.p-button,
.p-splitbutton.p-button-danger>.p-button {
    color: $dangerButtonTextColor;
    background: $dangerButtonBg;
    border: $dangerButtonBorder;

    &:not(:disabled):hover {
        background: $dangerButtonHoverBg;
        color: $dangerButtonTextHoverColor;
        border-color: $dangerButtonHoverBorderColor;
    }

    &:not(:disabled):focus {
        box-shadow: $dangerButtonFocusShadow;
    }

    &:not(:disabled):active {
        background: $dangerButtonActiveBg;
        color: $dangerButtonTextActiveColor;
        border-color: $dangerButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $dangerButtonBg;
        border: $outlinedButtonBorder;

        &:not(:disabled):hover {
            background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
            color: $dangerButtonBg;
            border: $outlinedButtonBorder;
        }

        &:not(:disabled):active {
            background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
            color: $dangerButtonBg;
            border: $outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $dangerButtonBg;
        border-color: transparent;

        &:not(:disabled):hover {
            background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
            border-color: transparent;
            color: $dangerButtonBg;
        }

        &:not(:disabled):active {
            background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
            border-color: transparent;
            color: $dangerButtonBg;
        }
    }
}

@if variable-exists(contrastButtonTextColor) {

    .p-button.p-button-contrast,
    .p-button-group.p-button-contrast>.p-button,
    .p-splitbutton.p-button-contrast>.p-button {
        color: $contrastButtonTextColor;
        background: $contrastButtonBg;
        border: $contrastButtonBorder;

        &:not(:disabled):hover {
            background: $contrastButtonHoverBg;
            color: $contrastButtonTextHoverColor;
            border-color: $contrastButtonHoverBorderColor;
        }

        &:not(:disabled):focus {
            box-shadow: $contrastButtonFocusShadow;
        }

        &:not(:disabled):active {
            background: $contrastButtonActiveBg;
            color: $contrastButtonTextActiveColor;
            border-color: $contrastButtonActiveBorderColor;
        }

        &.p-button-outlined {
            background-color: transparent;
            color: $contrastButtonBg;
            border: $outlinedButtonBorder;

            &:not(:disabled):hover {
                background: rgba($contrastButtonBg, $textButtonHoverBgOpacity);
                color: $contrastButtonBg;
                border: $outlinedButtonBorder;
            }

            &:not(:disabled):active {
                background: rgba($contrastButtonBg, $textButtonActiveBgOpacity);
                color: $contrastButtonBg;
                border: $outlinedButtonBorder;
            }
        }

        &.p-button-text {
            background-color: transparent;
            color: $contrastButtonBg;
            border-color: transparent;

            &:not(:disabled):hover {
                background: rgba($contrastButtonBg, $textButtonHoverBgOpacity);
                border-color: transparent;
                color: $contrastButtonBg;
            }

            &:not(:disabled):active {
                background: rgba($contrastButtonBg, $textButtonActiveBgOpacity);
                border-color: transparent;
                color: $contrastButtonBg;
            }
        }
    }
}

.p-button.p-button-link {
    color: var(--heading-color);
    background: transparent;
    border: transparent;
    font-size: 0.875em;
    text-decoration: underline;

    &:not(:disabled):hover {
        background: transparent;
        color: var(--heading-color);
        border-color: transparent;

        .p-button-label {
            text-decoration: $linkButtonTextHoverDecoration;
        }
    }

    &:not(:disabled):focus {
        background: transparent;
        box-shadow: $linkButtonFocusShadow;
        border-color: transparent;
    }

    &:not(:disabled):active {
        background: transparent;
        color: var(--heading-color);
        border-color: transparent;
    }
}

.discom-state-detail .discom-state-header, .breadcrumb {
    .back-btn {
        .p-button {
            background-color: transparent;
            gap: 8px;
            padding: 4px 12px 4px 0;
            border: 0;
            border-right: 1px solid $srt-neutral-2300;
            margin-right: 16px;
            border-radius: 0;

            .p-button-icon {
                width: 25px;
                height: 25px;
                background-color: var(--heading-color);
                border-radius: 100%;
                display: flex !important;
                justify-content: center;
                align-items: center;
                font-size: 0.688em;
                margin: 0;
            }

            .p-button-label {
                font-size: 0.875em;
                font-weight: 500;
                line-height: 1.5;
                color: var(--text-color);

                @media screen and (max-width: 1200px) {
                    font-size: 0.75em;
                }
            }
        }
    }
}

.consumer-application {
    .p-button {
        background: linear-gradient(99.08deg, var(--heading-color) 1.57%, #169FDA 100%);
        border: unset;
        padding: 20px 32px;
        gap: 10px;
        height: 100px;
        left: 35%;

        @media screen and (max-width: 1400px) {
            padding: 12px 22px;
        }
        
        @media screen and (max-width: 1200px) {
            padding: 10px 20px;
            gap: 8px;
            left: auto;
        }
        
        @media screen and (max-width: 576px) {
            padding: 10px 18px;
            gap: 4px;
        }

        .p-button-icon {
            font-size: 1.375em;
            color: var(--bg-color);
            margin: 0;

            @media screen and (max-width: 1400px) {
                font-size: 1em;
            }

            @media screen and (max-width: 768px) {
                font-size: 1.125em;
            }
            
            @media screen and (max-width: 576px) {
               font-size: 1em;
            }
        }

        .p-button-label {
            font-size: 1.563em;
            font-weight: 500;
            line-height: 1.5;
            color: var(--bg-color);

            @media screen and (max-width: 1400px) {
               font-size: 1em;
            }

            @media screen and (max-width: 1200px) {
                font-size: 0.875em;
            }

            @media screen and (max-width: 768px) {
                font-size: 0.75em;
            }
        }
    }
}

.complete-profile-dialog,
.consumer-profile,
.consumer-app-popup {
    .p-button {
        .p-button-label {
            font-size: 0.875em;
            font-weight: 500;
            line-height: 1.5;

            @media screen and (max-width: 1400px) {
                font-size: 0.75em;
            }
        }
    }
}

.consumer-app-popup {
    .p-button{
        width: 100%;
    }
}

.get-otp, 
.cancel-btn-app {
    .p-button {
        min-width: 177px;
        margin-top: 29px;

        @media screen and (max-width: 1400px) {
            margin-top: 23px;
        }

        @media screen and (max-width: 992px) {
            margin-top: 0;
        }
    }
}

.cancel-btn-app,
.fetch-details-btn {
    .p-button {
        padding: 12px 10px;
        min-width: 177px;
        margin-top: 29px;

        @media screen and (max-width: 1400px) {
            margin-top: 23px;
            padding: 10px;
        }       
    }
}

.fetch-details-btn {
    .p-button {
        width: 100% !important;
    }
}

.cancel-btn-app {
    .p-button {
        margin: 0 16px 0 0!important;
        width: 100%;
        height: 100%;

        .p-button-label {
            font-size: 0.875em;
            font-weight: 500;
            line-height: 1.5;

            @media screen and (max-width: 1400px) {
                font-size: 0.75em;
            }
        }
    }
}

.save-btn {
    .p-button {
        border: 1px solid var(--bg-color);
        padding: 12px 16px;
        background-color: var(--heading-color);
        cursor: pointer;

        .p-button-label {
            color: var(--bg-color);
        }
    }
}

.cancel-btn {
    .p-button {
        background-color: unset;
        border: unset;
        border-radius: unset;
        padding: 0;

        .p-button-icon {
            background-color: var(--heading-color);
            width: 40px;
            height: 40px;
            border-radius: 100%;
            display: flex !important;
            justify-content: center;
            align-items: center;
            font-size: 1em !important;

            @media screen and (max-width: 1400px) {
                width: 35px;
                height: 35px;
                font-size: 0.875em !important;
            }

            @media screen and (max-width: 1200px) {
                width: 30px;
                height: 30px;
                font-size: 0.75 !important;
            }

            @media screen and (max-width: 992px) {
                width: 25px;
                height: 25px;
            }
        }

        .p-button-label {
            color: $srt-black;
        }
    }
}

.save-btn-app {
    .p-button {
        width: 100%;
        height: 100%;
        padding: 12px 16px;
        cursor: pointer;

        .p-button-label {
            font-size: 0.875em;
            font-weight: 500;
            line-height: 1.5;

            @media screen and (max-width: 1400px) {
                font-size: 0.75em;
            }
        }
    }
}

.final-submission-details .save-btn-app {
    .p-button {
        .p-button-label {
           font-size: 1em;

            @media screen and (max-width: 1400px) {
                font-size: 0.875em;
            }
        }
    }
}

.consumer-profile {
    .get-otp {
        .p-button {
            @media screen and (max-width: 768px) {
                margin: 0;
            }
        }
    }
}

.submit-detail {
    .p-button {
        padding: 10px 16px;

        .p-button-icon {
            width: 24px;
            height: 24px;
            align-items: center;
            justify-content: center;
            display: flex !important;
            font-size: 0.875em;
            margin: 0;
        }
    }
}

.mobile-bars {
    .p-button {
        background-color:transparent;
        border: none;

        .pi {
            color:var(--heading-color);
            font-size: 1.875em;
        }
    }
}

.sidebar-close {
    .p-button.p-button-outlined {
        border: none;
        color: var(--text-color);
    }
    span {
        color: var(--text-color);
    }
}

.vendor-dialog {
    .p-button {
        width: 100%;
        border-radius: 8px;
        padding: 12px;
       font-size: 1em;
        font-weight: 500;
        line-height: 1.5;

        @media screen and (max-width: 1400px) {
            font-size: 0.875em;
            padding: 10px;
        }
    }
}

.new-btn {
    p-button{
        width: 100%;
        .p-button {
            background: #1FA822!important;
            color: var(--bg-color);
            border: 1px solid #1FA822;
            width: 100%;
            font-size: 0.875em;
        }
    }
   
}

.breadcrumb-container {
    .p-button {
        background: var(--bg-color);
        color: $srt-black;
        border: none;

        &:hover{
            background: var(--heading-color);
        }
    }
}

.footer-btn {
    .p-button {
        flex-direction: row-reverse;
    }
}

.vendor-button {
    .p-button.p-button-outlined {
        background-color: #C73037;
        color: var(--bg-color);
        border: 1px solid;
    }

    .p-button.p-button-outlined:hover {
        background-color: #C73037;
        color: var(--bg-color);
        border: 1px solid;
    }
}

.blue-outline-button {
    .p-button.p-button-outlined {
        background-color: var(--heading-color);
        color: var(--button-color);
        border: 1px solid var(--heading-color);
    }

    .p-button.p-button-outlined:hover {
        background-color: var(--heading-color);
        color: var(--button-color);
        border: 1px solid var(--heading-color);
    }
}

.add-series {
    .p-button {
        background-color: unset !important;
        border: unset;
        padding: 0;

        .p-button-label, .p-button-icon {
            font-size: 0.875em;
            font-weight: 500;
            line-height: 1.5;
            color: $srt-neutral-2300;

            @media screen and (max-width: 1400px) {
                font-size: 0.75em;
            }
        }
    }
}

.p-vendor-button-view{
    background-color: transparent;
    color: var(--text-color);
    border:none;
    width: auto !important;
    font-size: 0.75em;
    &:not(:disabled):active{
        background-color: transparent;
        color: var(--text-color);
    }
}

.p-vendor-button-delete{
    background-color: transparent;
    color:#e21238;
    border:none;
    width: auto !important;
    font-size: 0.75em;
    &:not(:disabled):active{
        background-color: transparent;
        color:#e21238;
    }
}

.p-otp-button{
    padding: 0 !important;
    margin-bottom: 8px;
}

.submit-details-popup .selected-values {
    .p-button {
        background-color: transparent;
        border: unset;
        width: 24px;
        height: 24px;
        padding: 0;

        @media screen and (max-width: 1400px) {
            width: 20px;
            height: 20px;
        }

        .p-button-icon {
            font-size: 0.875em;
            color: $srt-black;

            @media screen and (max-width: 1400px) {
                font-size: 0.75em;
            }
        }
    }
}

.view-img {
    .p-button {
        border: 1px solid var(--heading-color);
        background-color: transparent !important;
        border-radius: 53px;
        padding: 5px 17px;

        .p-button-label {
            font-size: 0.875em;
            font-weight: 500;
            color: var(--heading-color);
            line-height: 1.5;

            @media screen and (max-width: 1400px) {
                font-size: 0.75em;
            }
        }
    }
}

.inner-timeline{
    .p-button{
        background: transparent!important;
        border: none;
    }
}

.p-review-sort-button{
    width: 130px;
}

.blue-btn {
    .p-button {
        width: 100%;
        padding: 13px;
        background-color: var(--heading-color) !important;
        border-radius: 8px;

        @media screen and (max-width: 1400px) {
            padding: 10px 12px;
        }

        .p-button-icon {
            color: var(--bg-color);
        }

        .p-button-label {
            font-size: 0.875em;
            font-weight: 400;
            color: var(--bg-color);
            line-height: 1.5;

            @media screen and (max-width: 1400px) {
                font-size: 0.75em;
            }
        }
    }
}

.p-button.blue-btn {
    width: 100%;
    padding: 12px 20px;
    background-color: var(--heading-color) !important;
    border-radius: 8px;

    @media screen and (max-width: 1400px) {
        padding: 10px 12px;
    }

    .p-button-icon {
        color: var(--bg-color);
    }

    .p-button-label {
        font-size: 0.875em;
        font-weight: 400;
        color: var(--bg-color);
        line-height: 1.5;

        @media screen and (max-width: 1400px) {
            font-size: 0.75em;
        }
    }
}

.white-btn {
    .p-button {
        width: 100%;
        padding: 12px;
        background-color: transparent !important;
        border-radius: 8px;
        width: max-content;

        @media screen and (max-width: 1400px) {
            padding: 10px 12px;
        }

        .p-button-icon {
            color: var(--heading-color);
        }

        .p-button-label {
            font-size: 0.875em;
            font-weight: 400;
            color: var(--heading-color);
            line-height: 1.5;

            @media screen and (max-width: 1400px) {
                font-size: 0.75em;
            }
        }
    }
}

.p-button.white-btn {
    width: 100%;
    padding: 12px 20px;
    background-color: transparent !important;
    border-radius: 8px;
    width: max-content;

    @media screen and (max-width: 1400px) {
        padding: 10px 12px;
    }

    .p-button-icon {
        color: var(--heading-color);
    }

    .p-button-label {
        font-size: 0.875em;
        font-weight: 400;
        color: var(--heading-color);
        line-height: 1.5;

        @media screen and (max-width: 1400px) {
            font-size: 0.75em;
        }
    }
}

.vendor-submit-installation {
    .p-button-link {
        text-decoration: unset;
    }
}

.list-button {
    .p-button {
        padding: 0 10px;
        border: unset;
    }
}

.map-btn {
    .p-button {
        width: max-content;
    }
}

.p-file-button{
    padding: 5px 10px;
}

.p-list-button {
    background-color: transparent;
    border: none;
    color: $srt-neutral-2900;
    .pi {
        font-size: 0.7em;
    }
}

.p-state-dialog-footer-button{
    width: 275px !important;
    margin: 0 !important;    
}
.invert{
    .p-button{
        background: white!important;
        color:black!important;
        cursor: pointer;
        border: none;
        transition: none!important;
    }
}

.p-back-button {
    width: 25px !important;
    height: 25px !important;
    .pi {
        font-size: 0.688em;
    }
}

.financing-option {
    .detail-submit-btn {
        .p-button {
            width: 100%;
        }
    }
}
.view-link{
    .p-button{
        background: none!important;
        border: unset;
        color: var(--heading-color) !important;
        text-decoration: underline;
        transition: none;
    }
}

.discom-dashboard {
    .download-btn{
        .p-button {
            padding: 8px 10px;
            gap: 5px;
            border-radius: 8px;

            .p-button-label {
                font-size: 0.875em;
            }
        }
    }

    .download-icon {
        .p-button.p-button-link {
            width: fit-content;
            height: fit-content;
            padding: 0;
            border-radius: 0;
            text-decoration: unset;
            color: var(--text-color);
            box-shadow: unset;
        
            .pi {
                font-size: 0.875em;
                font-weight: 800 !important;
            }
        }
    }
}

.delete-btn {
    min-width: 40px;
    
    .p-button {
        padding: 0 10px;
    }
}

.knowledge-platform-discom .download-button {
    .p-button {
        padding: 7px 12px;

        .p-button-label {
            font-size: 0.875em;
        }
    }
}

.add-update-doc, .approve-feasibility-popup {
    .add-doc-btn {
        .p-button {
            width: 100%;
            height: 100%;
            padding: 7px;
            background-color: var(--heading-color) !important;
            border-radius: 6px;

            .p-button-label {
                font-size: 0.813em;
                font-weight: 400;
                color: var(--bg-color);
                line-height: 1.5;
            }
        }
    }
}

.add-update-doc, .reviewer-popup, .approve-feasibility-popup, .cancel-application {
    .submit-btn {
        .p-button {
            width: 100%;
            padding: 7px 16px;
            border-radius: 6px;
            min-width: 100px;

            .p-button-label {
                font-size: 0.875em;
                font-weight: 400;
                line-height: 1.5;
            }
        }
    }
}

.search-consumer {
    .p-search-button .p-button {
        // height: 100%;

        img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(82deg) brightness(105%) contrast(105%);
        }
    }

    .search-btn {
        .p-button {
            .p-button-label {
                font-size: 0.875em;
            }
        }
    }
}

.brand-selection-vendor, .redem-subsidy-btn, .app-dash-btn {
    .p-button {
        .p-button-label {
            font-size: 0.875em;
        }
    }
}

.redem-subsidy-btn-icon {
    .p-button.p-button-icon-only {
        padding: 0;
        height: 100%;
    }
}

.add-district-officer {
    .p-button.p-button-link {
        font-size: 0.75em;
        padding: 0;
    }
}

.linear-btn {
    background: linear-gradient(99.08deg, var(--heading-color) 1.57%, #169FDA 100%);
    border-radius: 10px;
    cursor: pointer;

    .p-button {
        background: unset;
        border: unset;
        padding: 14px;
        font-size: 0.875em;
        font-weight: 500;
        line-height: 1.2;
        color: #fff;
    }
}

.consumer-to-vendor-feedback-popup {
    .rating-btn {
        .p-button {
            border-radius: 8px;

            &:focus {
                box-shadow: unset !important;
            }

            &.p-button-secondary {
                border: 1px solid #0000004D !important;
                background: #FFFFFF !important;

                .p-button-label {
                    color: #1A1A1AB2;
                }
            }

            &.p-button-success {
                border: 1px solid #1A8BEA !important;
                background: linear-gradient(180deg, #5FB6FF 0%, #1A8BEA 100%) !important;

                .p-button-label {
                    color: #fff;
                }
            }

            .p-button-label {
                font-size: 0.688em;
                font-weight: 500;
                line-height: 1.2;
            }
        }
    }

    .skip-btn {
        .p-button {
            border-radius: 8px;
            border: 1px solid #5FB6FF;
            background: #fff;

            .p-button-label {
                font-size: 0.7em;
                font-weight: 400;
                line-height: 1.2;
                color: #5FB6FF;
            }
        }
    }

    .submit-now-btn {
        .p-button {
            border-radius: 8px;
            border: 1px solid var(--heading-color);
            background: linear-gradient(90.7deg, var(--heading-color) 0.21%, #2099FF 100%);
            margin: 0;

            .p-button-label {
                font-size: 0.7em;
                font-weight: 400;
                line-height: 1.2;
                color: #FFF;
            }
        }
    }
}


.change-request-table-admin {
    .action-column {
        .p-button.p-button-link {
            padding: 10px 5px;
        }
    }
}

.approval-table-admin, .pfms-payment-table-admin, .pfms-benefi-table-admin {
    .action-column {
        .p-button.p-button-link {
            padding: 10px 5px;
            box-shadow: unset;
        }
    }
}
.rp-button {
    min-height: 38px;
}