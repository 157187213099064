.p-panel {
    .p-panel-header {
        border: $panelHeaderBorder;
        padding: $panelHeaderPadding;
        background: $panelHeaderBg;
        color: $panelHeaderTextColor;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;

        .p-panel-title {
            font-weight: $panelHeaderFontWeight;
        }

        .p-panel-header-icon {
            @include action-icon();
        }
    }

    &.p-panel-toggleable {
        .p-panel-header {
            padding: $panelToggleableHeaderPadding;
        }
    }

    .p-panel-content {
        padding: $panelContentPadding;
        border: $panelContentBorder;
        background: var(--bg-color);
        color: var(--text-color);
        border-top: 0 none;

        &:last-child {
            border-bottom-right-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
        }
    }

    .p-panel-footer {
        padding: $panelFooterPadding;
        border: $panelFooterBorder;
        background: $panelFooterBg;
        color: $panelFooterTextColor;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
        border-top: 0 none;
    }

    .p-panel-icons-end {
        order: 2;
        margin-left: auto;
    }    

    .p-panel-icons-start {
        order: 0;
        margin-right: $inlineSpacing;
    }

    .p-panel-icons-center {
        order: 2;
        width:100%;
        text-align:center;
    }
}

.discom-state-detail, .discom_incentive_list {
    .p-panel {
        border-radius: 14px;
        border: 1px solid var(--form-input-border);
        overflow: hidden;

        .p-panel-header {
            border: unset;
            border-radius: unset;
            background-color: var(--bg-sitemap-page);
            padding: 0.5rem 1rem;
            border-bottom: 1px solid var(--form-input-border);

            .p-panel-title {
                color: var(--heading-color);
                font-size: 1em;
                font-weight: 500;
                line-height: 1.5;

                @media screen and (max-width: 1400px) {
                    font-size: 1.125em;
                }
                
                @media screen and (max-width: 1200px) {
                   font-size: 1em;
                }

                @media screen and (max-width: 992px) {
                    font-size: 0.875em;
                }
                
                @media screen and (max-width: 576px) {
                    font-size: 0.75em;
                }
            }

            .p-panel-icons {
                .p-panel-header-icon {
                    background-color: transparent !important;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .p-panel-content {
            border: unset;
            border-radius: unset;
            padding: 0;

            ul {
                li {
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 11px;

                    &.vertical-divider {
                        border-right: 1px solid rgba(175, 175, 175, 0.349);
                        margin: 0.5rem 0;
                        padding: 0 0.5rem !important;

                        @media screen and (max-width: 768px) {
                            border: unset;
                        }

                        &:nth-child(3n) {
                            border: unset;

                            @media screen and (max-width: 992px) {
                                border-right: 1px solid rgba(175, 175, 175, 0.349);
                            }

                            @media screen and (max-width: 768px) {
                                border: unset;
                            }
                        }

                        &:nth-child(2n) {
                            border-right: 1px solid rgba(175, 175, 175, 0.349);

                            @media screen and (max-width: 992px) {
                                border: unset;
                            }
                        }
                    }

                    .img-wrap {
                        background-color: var(--heading-color);
                        max-width: 40px;
                        width: 100%;
                        height: 40px;
                        border-radius: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .icon {
                            color: var(--bg-color-block) !important;
                            font-size: 1.125em;

                            @media screen and (max-width: 1400px) {
                               font-size: 1em;
                            }

                            &:before {
                                color: var(--bg-color-block) !important;
                            }
                        }

                        @media screen and (max-width: 1400px) {
                            max-width: 32px;
                            height: 32px;
                        }
                        
                        @media screen and (max-width: 1200px) {
                            max-width: 28px;
                            height: 28px;
                        }

                        img {
                            width: 24px;
                            height: 24px !important;

                            @media screen and (max-width: 1400px) {
                                width: 20px;
                                height: 20px !important;
                            }
                            
                            @media screen and (max-width: 1200px) {
                                width: 18px;
                                height: 18px !important;
                            }
                        }
                    }

                    label {
                        text-transform: uppercase;
                        color: var(--label-color);
                        font-size: 0.75em;
                        font-weight: 400;
                        line-height: 1.5;
                        display: block;
                        margin-bottom: 4px;
                    }

                    a {
                        color: var(--heading-color);
                        font-size: 0.7em;
                        font-weight: 500;
                        line-height: 1.5;
                        display: block;
                        overflow-wrap: anywhere;

                        @media screen and (max-width: 1400px) {
                            font-size: 0.813em;
                        }

                        @media screen and (max-width: 1200px) {
                            font-size: 0.75em;
                        }
                    }

                    address {
                        font-size: 1.125em;
                        font-weight: 600;
                        font-style: normal;
                        line-height: 1.5;

                        @media screen and (max-width: 1400px) {
                            font-size: 0.875em;
                        }
                        
                        @media screen and (max-width: 768px) {
                            font-size: 0.75em;
                        }
                    }

                    p {
                        font-size: 0.7em;
                        font-weight: 600 !important;
                        line-height: 1.5 !important;
                        display: block;
                        margin: unset !important;
                        overflow-wrap: anywhere;

                        @media screen and (max-width: 1400px) {
                            font-size: 0.813em;
                        }
                    }

                    h4 {
                       font-size: 1em;
                        font-weight: 500;
                        color: $srt-neutral-2900;

                        @media screen and (max-width: 576px) {
                            font-size: 0.875em;
                        }
                    }
                }
            }
        }
    }
}

.imp-note{

    li{
        padding: 6px;
        font-size: 0.875em;
    }
    .p-panel .p-panel-header{
        border:none;
        display: none;
    }
    .p-panel .p-panel-content {
        padding: 12px 4rem;
        border: 1px solid #dee2e6;
        border-radius: 11px;
        background: #ffe8a4;
        color: #000;
        border-top: 0 none;
        margin: 0px 18px 18px 18px;
        border: 1px solid #FF9900;
    }   
}
.remark {
    .p-panel .p-panel-header{
        border:none;
        display: none;
    }
    .p-panel .p-panel-content {
        padding: 12px 4rem;
        border: 1px solid #e54646;
        border-radius: 11px;
        background: #fda3a3;
        color: #000;
        margin: 0px 18px 18px 18px;
    }   
}

.app-submit{
    .p-panel .p-panel-content {
        padding: 1rem 1rem;
        border: 1px solid #1FA822;
        border-radius: 11px;
        background: #F0FFF0;
        color: #000;
        margin: 18px;
    } 
}

.p-panel-login{
    .p-panel-header{
        background: #3C8EE3;
        border:none;
        .p-panel-title{
            font-size: 1.5em;
            color:$primaryWhiteColor;
            font-weight: 600;
        }
        
    }
    .p-panel-content{
        background: #003C6E;
        color:$primaryWhiteColor;
        border: none;
        
    }
}

.p-panel-register{
    margin-top: 130px;
    @media only screen and (max-width: 767px){
        margin-top: 10px;
    }
}

.app-submit{
    .p-panel .p-panel-header{
        display: none;
    }
}

.remark-admin {
    .p-panel .p-panel-content {
        margin: 10px 0;
    }
}