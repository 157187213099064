@use "sass:math";

//core
.p-stepper .p-stepper-nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-x: auto;
}

.p-stepper-vertical .p-stepper-nav {
    flex-direction: column;
}

.p-stepper-header {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    align-items: center;

    &:last-of-type {
        flex: initial;
    }
}

.p-stepper-header .p-stepper-action {
    border: 0 none;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    &:focus-visible {
        @include focused();
    }
}

.p-stepper.p-stepper-readonly .p-stepper-header {
    cursor: auto;
}

.p-stepper-header.p-highlight .p-stepper-action {
    cursor: default;
}

.p-stepper-title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.p-stepper-number {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-stepper-separator {
    flex: 1 1 0;
}

//theme
.p-stepper {
    .p-stepper-nav {
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .p-stepper-header {
        padding: $inlineSpacing;

        .p-stepper-action {
            transition: $listItemTransition;
            border-radius: $borderRadius;
            background: $stepsItemBg;
            outline-color: transparent;

            .p-stepper-number {
                color: $stepsItemNumberColor;
                border: $stepsItemBorder;
                border-width: 2px;
                background: $stepsItemBg;
                min-width: $stepsItemNumberWidth;
                height: $stepsItemNumberHeight;
                line-height: $stepsItemNumberHeight;
                font-size: $stepsItemNumberFontSize;
                border-radius: $stepsItemNumberBorderRadius;
                transition: $actionIconTransition;
            }

            .p-stepper-title {
                margin-left: $inlineSpacing;
                color: $stepsItemTextColor;
                font-weight: $stepsItemActiveFontWeight;
                transition: $actionIconTransition;
            }

            &:not(.p-disabled):focus-visible {
                @include focused();
            }
        }

        &.p-highlight {
            .p-stepper-number {
                background: $highlightBg;
                color: $highlightTextColor;
            }

            .p-stepper-title {
                color: $textColor;
            }
        }

        &:not(.p-disabled):focus-visible {
            @include focused();
        }

        &:has(~ .p-highlight) {
            @if variable-exists(primaryColor) {
                .p-stepper-separator {
                    background-color: var(--heading-color);
                }
            }
        }
    }

    .p-stepper-panels {
        background: $tabviewContentBg;
        padding: $tabviewContentPadding;
        color: $tabviewContentTextColor;
    }

    .p-stepper-separator {
        background-color: $timelineEventColor;
        width: 100%;
        height: 2px;
        margin-inline-start: calc($inlineSpacing * 2);
        transition: $listItemTransition;
    }

    &.p-stepper-vertical {
        display: flex;
        flex-direction: column;

        .p-stepper-toggleable-content {
            display: flex;
            flex: 1 1 auto;
            background: $tabviewContentBg;
            color: $tabviewContentTextColor;
        }

        .p-stepper-panel {
            display: flex;
            flex-direction: column;
            flex: initial;

            &.p-stepper-panel-active {
                flex: 1 1 auto;
            }

            .p-stepper-header {
                flex: initial;
            }

            .p-stepper-content {
                width: 100%;
                padding-left: calc($inlineSpacing * 2);
            }

            .p-stepper-separator {
                flex: 0 0 auto;
                width: 2px;
                height: auto;
                margin-inline-start: calc($inlineSpacing * 7 / 2 + 2px);
            }

            &:has(~ .p-stepper-panel-active) {
                @if variable-exists(primaryColor) {
                    .p-stepper-separator {
                        background-color: var(--heading-color);
                    }
                }
            }

            &:last-of-type {
                .p-stepper-content {
                    padding-left: calc($inlineSpacing * 6);
                }
            }
        }
    }
}

.vendor-submit-installation {
    .p-stepper {
        .p-stepper-panels {
            padding: 0;
        }

        .p-stepper-nav {
            padding: 10px 0 80px 0 !important;
            max-width: 1000px;
            margin: 0 auto !important;
            overflow: visible;

            @media screen and (max-width: 1400px) {
                max-width: 800px;
            }

            .p-stepper-header {
                position: relative;

                &:has(~ .p-highlight) .p-stepper-action .marker {
                    border-color: #1FA822;
                }
                &.p-highlight .p-stepper-action .marker {
                    border-color: #FF9900;
                }

                &:nth-child(1) {
                    .p-stepper-action h3 {
                            background-color: #9757D7;
                    }
                }
                &:nth-child(2) {
                    .p-stepper-action h3 {
                            background-color: #EF466F;
                    }
                }
                &:nth-child(3) {
                    .p-stepper-action h3 {
                            background-color: #45B26B;
                    }
                }
                &:nth-child(4) {
                    .p-stepper-action h3 {
                            background-color: #2196F3;
                    }
                }
                &:nth-child(5) {
                    .p-stepper-action h3 {
                            background-color: #CC5187;
                    }
                }

                .p-stepper-action {
                    display: flex;
                    flex-direction: column;

                    .marker {
                        border: 3.5px solid #AFAFAF;
                        width: 14px;
                        height: 14px;
                        border-radius: 100%;
                    }

                    .marker-content {
                        position: absolute;
                        width: 200px;
                        left: -85px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;   
                        
                        @media screen and (max-width: 1400px) {
                            width: 170px;
                            left: -70px;
                        }
                    }

                    h3 {
                        border-radius: 100px;
                        padding: 2px 12px !important;
                        font-size: 0.875em;
                        font-weight: 500;
                        line-height: 1.5;
                        color: $srt-neutral-3900;
                        display: inline-block;
                        margin-top: 14px !important;
                        background-color: #9757D7;
                        width: fit-content;
                        
                        @media screen and (max-width: 1400px) {
                            font-size: 0.75em;
                        }
                    }

                    p {
                        font-size: 0.875em;
                        font-weight: 500 !important;
                        line-height: 1.3 !important;
                        color: $srt-black;
                        margin: 14px 0 0 !important;
                        text-align: center;
                        
                        @media screen and (max-width: 1400px) {
                            font-size: 0.75em;
                            margin: 12px 0 0 !important;
                        }
                    }
                }
            }
        }

        .p-stepper-separator {
            background-color: unset !important;
            border-top: 2px dashed #AFAFAF;
            height: 2px;
        }
    }
}