@import "primeflex/primeflex.scss";
@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.css";
@import "../src/assets/scss/reset.scss";
@import "../src/assets/scss/webfont.scss";
@import "../src/assets/themes/mytheme/theme.scss";
@import "../src/assets/themes/mytheme/dark-theme.scss";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

:root {
    --font-family: 'Poppins';
    --maskbg: #{$maskBg};
    --text-color: #000;
    --text-color-light: rgba(0, 0, 0, 0.5);
    --heading-color: #015296;
    --bg-color: #ffffff;
    --bg-color-header: #015296;
    --bg-color-block: #fff;
    --bg-announcements: #DFEFFF;
    --announcements-slider-border: rgba(1, 82, 150, 0.35);
    --bg-footer-page: #F6FBFF;
    --bg-sitemap-page: #E4EEFF;
    --bg-breadcrum: #FAFAFA;
    --bg-active-color: #F2FAFF;
    --border-what-new: #f2f2f2;
    --active-link-color: #015296;
    --text-blur: #015296b3;
    --text-gray: #525252;
    --button-color: #fff;
    --button-disabled-color: rgba(1, 82, 150, 0.5);
    --border-menubar: #E2E2E2;
    --border-input: #E2E2E2;
    --bg-download-wrap: #f5f5f5;
    --text-faq: rgba(60, 60, 67, 0.85);
    --benefits-border: rgba(0, 0, 0, 0.3);
    --benefits-label: rgba(0, 0, 0, 0.7);
    --vendor-filter-border: #e2e2e2;
    --form-input-bg: #ffffff;
    --form-input-border: #e2e2e2;
    --form-focus: #e9ecef;
    --accordion-heading: #000;
    --label-color: #494949;
    --vendor-wrap: #E4EEFF;
    --user-login-bg: #015296;
    --button-register-bg: #fff;
    --vendor-bg:#015296;
    --steps-bg:#f5f5f5;
}

body.dark-theme {
    --text-color: #fff;
    --text-color-light: #fff;
    --heading-color: #bdd0fb;
    --bg-color: #1a1d21;
    --bg-color-header: #bdd0fb;
    --bg-color-block: #212529;
    --bg-announcements: #212529;
    --announcements-slider-border: #bdd0fb;
    --bg-footer-page: #1a1d21;
    --bg-sitemap-page: #212529;
    --bg-breadcrum: #212529;
    --bg-active-color: #212529;
    --border-what-new: #313131;
    --active-link-color: #bdd0fb;
    --text-blur: #bbb;
    --text-gray: #bbb;
    --button-color: #1a1d21;
    --button-disabled-color: rgba(189, 208, 251, 0.5);
    --border-menubar: rgba(255, 255, 255, 0.4);
    --border-input: rgba(255, 255, 255, 0.1);
    --bg-download-wrap: #212529;
    --text-faq: rgba(187, 187, 187, 1);
    --benefits-border: #BDD0FB;
    --benefits-label: #fff;
    --vendor-filter-border: rgba(255, 255, 255, 0.4);
    --form-input-bg: #212529;
    --form-input-border: rgba(255, 255, 255, 0.1);
    --form-focus: #1a1d21;
    --accordion-heading: #bdd0fb;
    --label-color: #bbb;
    --vendor-wrap: #212529;
    --user-login-bg: #212529;
    --button-register-bg: #bdd0fb;
    --vendor-bg:#212529;
    --steps-bg:#212529;


}

body {
    color: var(--text-color);
    background: var(--bg-color);
    font-family: 'Poppins';
    font-size: 1em;
    overflow-x: hidden;
    transition: background-color 0.3s ease, color 0.3s ease;

    @media only screen and (max-width: 767px) {
        font-size: 0.875em;
    }
}


::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    border: 1px solid var(--form-input-border);
    border-radius: 5px;
    background-color: var(--bg-color-block);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--heading-color);
    border-radius: 5px;
}

a {
    text-decoration: none;
    cursor: pointer;
    transition: all ease-in-out 0.5s;
}

img {
    max-width: 100%;
    height: auto;
}

.grid>.col,
.grid>[class*=col] {
    padding-top: 0;
    padding-bottom: 0;
}

.container {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;

    @media only screen and (max-width: 768px) {
        padding-right: .625rem;
        padding-left: .625rem;
    }

    @media only screen and (min-width: 576px) {
        max-width: 540px;
    }

    @media only screen and (min-width: 768px) {
        max-width: 720px;

    }

    @media only screen and (min-width: 992px) {
        max-width: 960px;
    }

    @media only screen and (min-width: 1200px) {
        max-width: 1140px;
    }
}

.container-fluid {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;

    @media only screen and (max-width: 768px) {
        padding-right: .625rem;
        padding-left: .625rem;
    }

}

.grid {
    margin-top: 0;
}

main {
    margin-top: 171.6px;
    min-height: calc(100vh - 409px);
}

@media only screen and (max-width: 1200px) {
    main {
        margin-top: 122.8px;
        min-height: auto
    }
}

@media only screen and (max-width: 767px) {
    main {
        margin-top: 126.8px;
        min-height: auto
    }
}

main.main-user {
    margin-top: 82.67px
}

main.no-margin {
    margin-top: 0
}


h2 {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--heading-color);

    @media only screen and (max-width: 767px) {
        font-size: 2em;
        line-height: 32px;
        margin-bottom: 1rem;
    }
}

p {
    font-weight: 400;
    margin-bottom: 1rem;
    line-height: 30px;

    @media only screen and (max-width: 767px) {
        line-height: 24px;
    }
}

.extra-links {
    background-color: var(--bg-footer-page);
    min-height: calc(100vh - 450px);

    @media only screen and (max-width: 767px) {
        min-height: auto;
    }

    h2 {
        font-size: 2em;
    }

    h3 {
        color: var(--heading-color);
        font-size: 1.375em;
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 30px;
    }

    p {
        margin-bottom: 0;
        font-size: 0.875em;
    }

    a {
        color: var(--heading-color);
        text-decoration: underline;
        font-weight: 500;
    }

    ul {
        list-style-type: disc;
        font-size: 0.875em;
        line-height: 36px;
        padding-left: 20px;

        @media only screen and (max-width: 767px) {
            line-height: 24px;
        }

    }
}

.text-underline {
    text-decoration: underline;
    color: var(--heading-color);
}

.required::after {
    content: "*";
    color: red;
    margin-left: 2px;
    line-height: 16px;
}

.no-border-radius {
    border-radius: 0;
}

.uppercase {
    text-transform: uppercase;
}

.sitemap-accordion {
    .p-accordion-header-link {
        font-weight: 500;
        border: none;
        padding: 15px;
        color: var(--heading-color);
        font-size: 1em;
        text-decoration: none;

        .icon:before {
            color: var(--heading-color);
        }

        @media only screen and (max-width: 767px) {
            margin-left: -15px;
        }
    }

    .p-accordion-tab {
        margin-bottom: 0;
    }

    .p-accordion-content {
        padding: 0;
        background: transparent;
        border: none;

        ul {
            list-style: none;
            padding-left: 0;

            li {
                border-bottom: 1px solid var(--form-input-border);

                .accordion-link {
                    color: var(--text-color);
                    font-size: 1em !important;
                    font-weight: 500 !important;
                    padding: 0 0 5px 20px;
                    display: inline-flex !important;

                    i {
                        color: var(--heading-color);
                    }
                }
            }
        }
    }
}

.calc-card {
    .p-card-body {
        padding: 0;
    }
}

.p-btn-pdf {
    padding: 5px 18px;
}

.card-feedback {
    margin-bottom: 1rem;
}

.discom_incentive_list,
.consumer_change_request_list,
.search-no-result {
    display: grid;
    row-gap: 20px;


    h3 {
        font-size: 1.375em;
        font-weight: 600;
        color: var(--label-color);
        line-height: 1.5;

        @media screen and (max-width: 1400px) {
            font-size: 1em;
        }

        @media screen and (max-width: 1200px) {
            font-size: 1.125em;
        }

        @media screen and (max-width: 768px) {
            font-size: 1em;
        }
    }

    .no_data {
        border: 1px solid var(--form-input-border);
        background-color: var(--bg-download-wrap);
        border-radius: 10px;
        margin-top: 1rem 0;

        img {
            width: auto;
            max-height: 384px;
            margin: 0 auto;
            display: block;
        }
    }
}

.pi-trash {
    color: red;
}

.change-password-icon {
    width: 20px;
    height: 20px;
    background-color: #015296;
    mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><g><path d="M42,23H10c-2.2,0-4,1.8-4,4v19c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V27C46,24.8,44.2,23,42,23z M31,44.5c-1.5,1-3.2,1.5-5,1.5c-0.6,0-1.2-0.1-1.8-0.2c-2.4-0.5-4.4-1.8-5.7-3.8l3.3-2.2c0.7,1.1,1.9,1.9,3.2,2.1c1.3,0.3,2.6,0,3.8-0.8c2.3-1.5,2.9-4.7,1.4-6.9c-0.7-1.1-1.9-1.9-3.2-2.1c-1.3-0.3-2.6,0-3.8,0.8c-0.3,0.2-0.5,0.4-0.7,0.6L26,37h-9v-9l2.6,2.6c0.4-0.4,0.9-0.8,1.3-1.1c2-1.3,4.4-1.8,6.8-1.4c2.4,0.5,4.4,1.8,5.7,3.8C36.2,36.1,35.1,41.7,31,44.5z"/><path d="M10,18.1v0.4C10,18.4,10,18.3,10,18.1C10,18.1,10,18.1,10,18.1z"/><path d="M11,19h4c0.6,0,1-0.3,1-0.9V18c0-5.7,4.9-10.4,10.7-10C32,8.4,36,13,36,18.4v-0.3c0,0.6,0.4,0.9,1,0.9h4c0.6,0,1-0.3,1-0.9V18c0-9.1-7.6-16.4-16.8-16c-8.5,0.4-15,7.6-15.2,16.1C10.1,18.6,10.5,19,11,19z"/></g></svg>') no-repeat center;
    mask-size: contain;
    -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><g><path d="M42,23H10c-2.2,0-4,1.8-4,4v19c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V27C46,24.8,44.2,23,42,23z M31,44.5c-1.5,1-3.2,1.5-5,1.5c-0.6,0-1.2-0.1-1.8-0.2c-2.4-0.5-4.4-1.8-5.7-3.8l3.3-2.2c0.7,1.1,1.9,1.9,3.2,2.1c1.3,0.3,2.6,0,3.8-0.8c2.3-1.5,2.9-4.7,1.4-6.9c-0.7-1.1-1.9-1.9-3.2-2.1c-1.3-0.3-2.6,0-3.8,0.8c-0.3,0.2-0.5,0.4-0.7,0.6L26,37h-9v-9l2.6,2.6c0.4-0.4,0.9-0.8,1.3-1.1c2-1.3,4.4-1.8,6.8-1.4c2.4,0.5,4.4,1.8,5.7,3.8C36.2,36.1,35.1,41.7,31,44.5z"/><path d="M10,18.1v0.4C10,18.4,10,18.3,10,18.1C10,18.1,10,18.1,10,18.1z"/><path d="M11,19h4c0.6,0,1-0.3,1-0.9V18c0-5.7,4.9-10.4,10.7-10C32,8.4,36,13,36,18.4v-0.3c0,0.6,0.4,0.9,1,0.9h4c0.6,0,1-0.3,1-0.9V18c0-9.1-7.6-16.4-16.8-16c-8.5,0.4-15,7.6-15.2,16.1C10.1,18.6,10.5,19,11,19z"/></g></svg>') no-repeat center;
    -webkit-mask-size: contain;
}

.consumer-note {
    .ql-container.ql-snow {
        border: none !important;
    }
}

.rp-view-application-wrap {
    .heading-wrap {
        padding: 10px 0;
        position: relative;
        background-color: #E6EBF1;
        border-radius: 10px;
        margin-bottom: 20px;

        &:after {
            content: "";
            border-top: 1px solid $primaryColor;
            width: calc(100% - 10px);
            position: absolute;
            inset: 0;
            margin: auto;
            height: 1px;
            right: 10px;
        }

        h2 {
            color: $primaryColor;
            font-size: 1em;
            font-weight: 600;
            margin: 0;
            display: block;
            width: -moz-fit-content;
            width: fit-content;
            background-color: #e6ebf1;
            z-index: 1;
            position: relative;
            padding: 0 10px;
        }
    }

    label {
        font-size: 0.875em;
        font-weight: 500;
        color: #2c63b8;
    }

    .form-values {
        padding: 0;
        border: none;
        font-size: 0.875em;
        font-weight: 400;
        color: #000;
        word-break: break-word;

        tr {
            border-bottom: 1px solid #d3d3d3;

            &:last-child {
                border-bottom: none;
            }

            td {
                padding: 12px;
            }
        }
    }
}

.rp-expand-table-detail {
    .grid {
        div {
            &:first-child {
                font-size: 0.875em;
                font-weight: 500;
                color: #2c63b8;
            }
        }
    }

}

.rp-label {
    font-size: 0.875em;
    font-weight: 500;
    color: #2c63b8;
}

.rp-value {
    font-size: 0.875em;
    font-weight: 400;
    color: #000;
}

//   CSTEP CSS START HERE
app-rtpv-design {
    .c-side {
        position: relative !important;
        max-height: calc(100vh - 119px);
        overflow: auto;
    }

    .map_section1 {
        height: calc(100vh - 119px) !important;
        top: 83px !important;
    }

    #go-top {
        display: none !important;
    }

    .bottom-footer {
        padding-top: 0;
        border-bottom: none;
    }
}

app-newrtpv {
    .c-side {
        position: relative !important;
        max-height: calc(100vh - 152px);
        overflow: auto;
    }

    .map_section1 {
        height: calc(100vh - 119px) !important;
        top: 83px !important;
    }

    #go-top {
        display: none !important;
    }

    .bottom-footer {
        padding-top: 0;
        border-bottom: none;
    }
}

app-rtse-wrapper {
    .next {
        font-size: 1em !important;
    }
}

ngb-modal-window {
    .modal-dialog {
        margin-top: 100px !important;
    }
}

.temp-class {
    .content-wrapper-collapse {
        margin-left: 0 !important;
    }

    .content-wrapper {
        margin-left: 0 !important;
        height: calc(100vh - 119px) !important;

        app-rtse-wrapper {
            .back-btn {
                position: fixed;
                z-index: 9;
                left: 75px;
            }
        }

        app-rtpv-design .c-side {
            max-height: calc(100vh - 186px);
        }
    }
}

.rp-consumer-temp-class {
    min-height: calc(100vh - 119px) !important;

    .content-wrapper {
        height: calc(100vh - 119px) !important;
    }

    app-rtse-wrapper {
        .back-btn {
            position: fixed;
            z-index: 9;
            left: 50px;
            //   top: 90px;
            top: 115px // Only for Strip
        }
    }
}

// CSTEP CSS END HERE

.sticky {
    position: initial !important;
}

.p-datatable-scrollable-table>.p-datatable-thead {
    z-index: 1;

    @media screen and (max-width:1200px) {
        position: unset;
    }
}

@media screen and (max-width:1200px) {
    .p-icon-field-left .p-input-icon:first-of-type {
        z-index: auto;
    }
}

.rp-trackbox {
    position: relative;
    margin-top: 1.5em;
}

.rp-mobile-view-ins-chart {
    @media screen and (max-width: 992px) {
        overflow-x: auto;
    }

    .chart-container {
        @media screen and (max-width: 992px) {
            flex-wrap: nowrap !important;
        }

        .chart-item {
            @media screen and (max-width: 992px) {
                width: 90% !important;
            }
        }
    }
}

.discom-dashboard .graphs .p-card .p-card-body {
    @media screen and (max-width: 992px) {
        padding: 10px;
    }
}

.dropdown-switch-container {
    @media screen and (max-width: 992px) {
        flex-direction: column;
        align-items: start;
        gap: 5px !important;
    }
}

.discom-dashboard .common-discom-filter .checkbox-group {
    @media screen and (max-width: 992px) {
        width: 90% !important;
    }
}

.discom-dashboard .p-tabview .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav {
    @media screen and (max-width: 992px) {
        display: ruby-text;
    }

    li .p-tabview-nav-link {
        @media screen and (max-width: 992px) {
            padding: 0.875rem;
        }
    }
}

.highlight-duplicate {
    color: red;
    font-weight: bold;
}

.cap-timer {
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.2;
    color: var(--text-color);
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    gap: 6px;

    @media screen and (max-width: 1400px) {
        font-size: 0.75em;

        .pi {
            font-size: 0.75em;
        }
    }
}

.highcharts-data-label-hidden {
    opacity: 1;
    pointer-events: all !important;
}