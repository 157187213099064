@mixin icon-override($icon) {
    &:before {
        content: $icon;
    }
}

@mixin focused() {
    outline: $focusOutline;
    outline-offset: $focusOutlineOffset;
    box-shadow: $focusShadow;
}

@mixin focused-inset() {
    outline: $focusOutline;
    outline-offset: -1 * $focusOutlineOffset;
    box-shadow: inset $focusShadow;
}

@mixin focused-input() {
    outline: $focusOutline;
    outline-offset: $inputFocusOutlineOffset;
    box-shadow: $focusShadow;
    border-color: $inputFocusBorderColor;
}

@mixin focused-listitem() {
    outline: $focusOutline;
    outline-offset: $focusOutlineOffset;
    box-shadow: $inputListItemFocusShadow;
}

@mixin invalid-input() {
    border-color: $inputErrorBorderColor;
}

@mixin menuitem-link {
    padding: $menuitemPadding;
    color: $primaryBlackColor;
    border-radius: $menuitemBorderRadius;
    transition: $listItemTransition;
    user-select: none;

    .p-menuitem-text {
        color: $menuitemTextColor;
    }

    .p-menuitem-icon {
        color: $primaryBlackColor;
        margin-right: $inlineSpacing;
    }

    .p-submenu-icon {
        color: $menuitemIconColor;
    }

    &:not(.p-disabled):hover {
        background: $menuitemHoverBg;

        .p-menuitem-text {
            color: $menuitemTextHoverColor;
        }

        .p-menuitem-icon {
            color: $menuitemIconHoverColor;
        }

        .p-submenu-icon {
            color: $menuitemIconHoverColor;
        }
    }

    &:focus {
        @include focused-listitem();
    }
}

@mixin menuitem {
    >.p-menuitem-content {
        transition: $listItemTransition;
        border-radius: $menuitemBorderRadius;
        padding: 8px 12px;
        font-size: 0.875em;
        text-transform: capitalize;
        font-weight: 400;
        color: var(--text-color);
        background-color: var(--bg-color);
        li{
            a{
                display: flex;
                align-items: center;
                gap:5px;
            }
        }

        .p-menuitem-link {
            color: var(--text-color);
            padding: $menuitemPadding;
            user-select: none;
            font-weight: 500 !important;
            padding: 10px !important;
            border-radius: 10px;
            font-size: 1em;


            .p-menuitem-text {
                color: var(--text-color);
                line-height: 20px;
                font-weight: 500;
            }

            .p-menuitem-icon {
                margin-right: $inlineSpacing;
                color: var(--heading-color) !important;
                font-size: 1.25em;
            }

            .p-submenu-icon {
                color: $menuitemIconColor;
            }
        }

        .p-menuitem-link-active {
            background: var(--bg-active-color);

            .p-menuitem-icon {
                color: var(--heading-color) !important;
            }

            .p-menuitem-text {
                color: var(--active-link-color) !important;

            }
        }
    }

    &.p-highlight {
        >.p-menuitem-content {
            // color: $menuitemTextActiveColor;
            // background: $menuitemActiveBg;
            background: var(--bg-active-color);
            color: var(--heading-color) !important;
            .p-menuitem-link {
                background: var(--bg-active-color);
                .p-menuitem-text {
                    // color: $menuitemTextActiveColor;
                    color: var(--heading-color) !important;
                }

                .p-menuitem-icon,
                .p-submenu-icon {
                    // color: $menuitemIconActiveColor;
                    color: var(--heading-color) !important;
                }
            }
        }

        &.p-focus {
            >.p-menuitem-content {
                background: var(--bg-active-color);
            }
        }
    }

    &:not(.p-highlight):not(.p-disabled) {
        &.p-focus {
            >.p-menuitem-content {
                background: var(--bg-active-color);
                color: var(--heading-color) !important;

                .p-menuitem-link {
                    .p-menuitem-text {
                        color: var(--heading-color) !important;
                    }

                    .p-menuitem-icon,
                    .p-submenu-icon {
                        color: var(--heading-color) !important;
                    }
                }
            }
        }

        >.p-menuitem-content {
            &:hover {
                background: var(--bg-active-color) !important;
                color: var(--heading-color) !important;

                .p-menuitem-link {
                    .p-menuitem-text {
                        color: var(--heading-color) !important;
                    }

                    .p-menuitem-icon,
                    .p-submenu-icon {
                        color: var(--heading-color) !important;
                    }
                }
            }
        }
    }
}

@mixin horizontal-rootmenuitem-link {
    padding: $horizontalMenuRootMenuitemPadding;
    color: var(--text-color);
    border-radius: $horizontalMenuRootMenuitemBorderRadius;
    transition: $listItemTransition;
    user-select: none;

    .p-menuitem-text {
        color: var(--text-color);
    }

    .p-menuitem-icon {
        color: $horizontalMenuRootMenuitemIconColor;
        margin-right: $inlineSpacing;
    }

    .p-submenu-icon {
        color: $horizontalMenuRootMenuitemIconColor;
        margin-left: $inlineSpacing;
    }

    &:not(.p-disabled):hover {
        background: $horizontalMenuRootMenuitemHoverBg;

        .p-menuitem-text {
            color: $horizontalMenuRootMenuitemTextHoverColor;
        }

        .p-menuitem-icon {
            color: $horizontalMenuRootMenuitemIconHoverColor;
        }

        .p-submenu-icon {
            color: $horizontalMenuRootMenuitemIconHoverColor;
        }
    }

    &:focus {
        @include focused-listitem();
    }
}

@mixin horizontal-rootmenuitem {
    >.p-menuitem-content {
        color: var(--text-color);
        transition: $listItemTransition;
        border-radius: $horizontalMenuRootMenuitemBorderRadius;

        .p-menuitem-link {
            padding: $horizontalMenuRootMenuitemPadding;
            user-select: none;

            .p-menuitem-text {
                color: var(--text-color);
            }

            .p-menuitem-icon {
                color: $horizontalMenuRootMenuitemIconColor;
                margin-right: $inlineSpacing;
            }

            .p-submenu-icon {
                color: $horizontalMenuRootMenuitemIconColor;
                margin-left: $inlineSpacing;
            }
        }
    }

    &:not(.p-highlight):not(.p-disabled) {
        >.p-menuitem-content {
            &:hover {
                color: $horizontalMenuRootMenuitemTextHoverColor;
                background: $horizontalMenuRootMenuitemHoverBg;

                .p-menuitem-link {
                    .p-menuitem-text {
                        color: $horizontalMenuRootMenuitemTextHoverColor;
                    }

                    .p-menuitem-icon,
                    .p-submenu-icon {
                        color: $horizontalMenuRootMenuitemIconHoverColor;
                    }
                }
            }
        }
    }
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content;
    }

    :-moz-placeholder {
        @content;
    }

    ::-moz-placeholder {
        @content;
    }

    :-ms-input-placeholder {
        @content;
    }
}

@mixin scaledPadding($val, $scale) {
    padding: nth($val, 1) * $scale nth($val, 2) * $scale;
}

@mixin scaledFontSize($val, $scale) {
    font-size: $val * $scale;
}

@mixin nested-submenu-indents($val, $index, $length) {
    .p-submenu-list {
        .p-menuitem {
            .p-menuitem-content {
                .p-menuitem-link {
                    padding-left: $val * ($index + 1);
                }
            }

            @if $index < $length {
                @include nested-submenu-indents($val, $index + 2, $length);
            }
        }
    }
}

@mixin action-icon($enabled: true) {
    width: $actionIconWidth;
    height: $actionIconHeight;
    color: $actionIconColor;
    border: $actionIconBorder;
    background: $actionIconBg;
    border-radius: $actionIconBorderRadius;
    transition: $actionIconTransition;

    &:enabled:hover {
        color: $actionIconHoverColor;
        border-color: $actionIconHoverBorderColor;
        background: $actionIconHoverBg;
    }

    &:focus-visible {
        @include focused();
    }
}

@function tint($color, $percentage) {
    @return mix(#fff, $color, $percentage);
}

@function shade($color, $percentage) {
    @return mix(#000, $color, $percentage);
}

@mixin focused-inset() {
    outline: $focusOutline;
    outline-offset: $focusOutlineOffset;
    box-shadow: inset $focusShadow;
}

@mixin menuitem-badge {
    background: $badgeBg;
    color: $badgeTextColor;
    font-size: $badgeFontSize;
    font-weight: $badgeFontWeight;
    min-width: $badgeMinWidth;
    height: $badgeHeight;
    line-height: $badgeHeight;
    border-radius: $borderRadius;
    margin-left: $inlineSpacing;
    padding-left: $inlineSpacing;
    padding-right: $inlineSpacing;
}