* {
	box-sizing: border-box;
}

.p-component {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: $fontSize;
    font-weight: $fontWeight;
}

.p-component-overlay {
    background-color: $maskBg;
    transition-duration: $transitionDuration;
}

.p-disabled, .p-component:disabled {
    opacity: $disabledOpacity;
}

.p-error {
    color: $errorColor;
}
.rp-word-break {
    word-break: break-word !important;
}
.rp-dialog-message {
    line-height: 1.4;
}
.rp-font-12 {
    font-size: 0.75em !important;
}
.p-text-secondary {
    color: $textSecondaryColor;
}

.pi {
    font-size: $primeIconFontSize;
}

.p-icon {
    width: $primeIconFontSize;
    height: $primeIconFontSize;
}

.p-link {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: $fontSize;
    border-radius: $borderRadius;

    &:focus-visible {
        @include focused();
    }
}

.p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards;
}

.p-component-overlay {
    @keyframes p-component-overlay-enter-animation {
        from {
            background-color: transparent;
        }
        to {
            background-color: var(--maskbg);
        }
    }

    @keyframes p-component-overlay-leave-animation {
        from {
            background-color: var(--maskbg);
        }
        to {
            background-color: transparent;
        }
    }
}
.table__title {
    h3 {
        font-size: 1em;
        font-weight: 600;
        line-height: 1.4;
        color: var(--heading-color);
        margin-bottom: 20px !important;
    }
}

.rp-error {
    font-size: 0.75em;
    font-weight: 500;
    color: red;
    margin-bottom: 0;
    line-height: 20px;
    &.error-blue{
        color:var(--heading-color);
    }
}
.rp-chips {
        background-color: #229AFF;
        color: #fff;
        border-radius: 15px;
        padding: 0.25rem 0.75rem;
        font-weight: 400;
        margin: 0 0.25rem 0.25rem 0;
        display: inline-flex;
}
.rp-view-details {
    .installer-labels {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        align-items: flex-start;

        span {
            color: #000;
            p {
                line-height: inherit !important;
            }
            img {
                max-height: 200px;
            }
        }

        label {
            width: 45%;
            text-align: left;
            word-wrap: break-word;
            font-size: 0.875em;
            font-weight: 500;
            color: #2c63b8;
        }

        a {
            text-decoration: underline;
            cursor: pointer;
        }

        span, a {
            width: 50%;
            text-align: left;
            word-wrap: break-word;
            font-weight: 400;
            font-size: 0.875em;

        }
    }
}

.rp-capitalize {
    .p-dropdown-item {
        span {
            text-transform: capitalize !important;
        }
    }
}

.custom-input-width{
    position: relative;
    top: 0.9em;
}
.rp-chart-box {
    border: 1px solid var(--heading-color);
    border-radius: 10px;
    height: 300px;
    padding: 2px 0;
    p {
        text-align: center;
    }
}
.rp-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px; /* Adjust width as needed */
  }

  .rp-min-height-30 {
    min-height: 30px;
}
.rp-error-msg {
    font-size: 0.75em;
    font-weight: 500;
    color: red;
    margin-top: 6px;
    margin-left: 6px;
}
.rp-red-text {
    color: red;
    font-size: 1.15em;
}
.dashboard-overview-card {
    .p-datatable .p-datatable-wrapper {
        margin-top: 5px;
        padding-top: 5px;
    }
    .p-datatable .p-datatable-thead {

        tr {
            border-radius: 10px;
            box-shadow: 0 0 2px 0px #0000003d;

            th {
                background-color: $srt-white !important;
                border-radius: 10px;

                .rp-chips-dash {
                    border-radius: 15px;
                    padding: 0.15rem 0.75rem;
                    font-weight: 400;
                    font-size: 0.75em;
                    display: inline-flex;

                    &.rp-registration-th {
                        background-color: $rp-primary-blue-200;
                        color: $srt-black;
                    }

                    &.rp-application-th {
                        background-color: $rp-primary-blue-300;
                        color: $srt-black;
                    }

                    &.rp-feasibility-th {
                        background-color: $rp-primary-blue-400;
                        color: $srt-white;
                    }

                    &.rp-vendor {
                        background-color: $rp-primary-blue-500;
                        color: $srt-white;
                    }

                    &.rp-installation-th {
                        background-color: $rp-primary-blue-600;
                        color: $srt-white;
                    }

                    &.rp-inspection-th {
                        background-color: $rp-primary-blue-700;
                        color: $srt-white;
                    }

                    &.rp-subsidy-th {
                        background-color: $rp-primary-blue-800;
                        color: $srt-white;
                    }
                }
            }
        }
    }

    .p-datatable .p-datatable-tbody {
        tr {
            td {
                padding: 10px !important;

                .rp-outer-border {
                    padding: 5px;
                    border-radius: 10px;

                    .p-card {
                        margin-bottom: 10px;
                        .p-card-body {
                            padding: 0;
                            border-radius: 6px;
                            background-color: $srt-secondary;

                            .p-card-content {
                                padding: 0;
                                border-radius: 6px;
                                border: none;

                            }

                            .amount {
                                position: relative;
                                min-height: 65px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;


                                h4,
                                h5,
                                h6 {
                                    font-weight: 500;
                                    line-height: 1.2;
                                    text-align: center;
                                    color: #000;
                                }

                                h4,
                                h6 {
                                    font-size: 0.688em;
                                }

                                h5 {
                                    font-size: 1.188em;
                                }
                            }

                            .status {
                                font-size: 0.75em;
                                font-weight: 500;
                                line-height: 1.4;
                                text-align: center;
                                padding: 3px 0;
                            }
                        }
                    }

                }

                &.rp-registration {
                    .rp-outer-border {
                        border: 2px dotted $rp-primary-blue-200;

                        .p-card-body {
                            border: 2px solid $rp-primary-blue-200;

                            .status {
                                background-color: $rp-primary-blue-200;
                                color: $srt-black;
                            }
                        }

                    }
                }

                &.rp-application {
                    .rp-outer-border {
                        border: 2px dotted $rp-primary-blue-200;

                        .p-card-body {
                            border: 2px solid $rp-primary-blue-300;

                            .status {
                                background-color: $rp-primary-blue-300;
                                color: $srt-black;
                            }
                        }
                    }
                }

                &.rp-feasibility {
                    .rp-outer-border {
                        border: 2px dotted $rp-primary-blue-300;

                        .p-card-body {
                            border: 2px solid $rp-primary-blue-400;

                            .status {
                                background-color: $rp-primary-blue-400;
                                color: $srt-white;
                            }
                        }
                    }
                }

                &.rp-vendor {
                    .rp-outer-border {
                        border: 2px dotted $rp-primary-blue-400;

                        .p-card-body {
                            border: 2px solid $rp-primary-blue-500;

                            .status {
                                background-color: $rp-primary-blue-500;
                                color: $srt-white;
                            }
                        }
                    }
                }

                &.rp-installation {
                    .rp-outer-border {
                        border: 2px dotted $rp-primary-blue-500;

                        .p-card-body {
                            border: 2px solid $rp-primary-blue-600;

                            .status {
                                background-color: $rp-primary-blue-600;
                                color: $srt-white;
                            }
                        }
                    }
                }

                &.rp-inspection {
                    .rp-outer-border {
                        border: 2px dotted $rp-primary-blue-600;

                        .p-card-body {
                            border: 2px solid $rp-primary-blue-700;

                            .status {
                                background-color: $rp-primary-blue-700;
                                color: $srt-white;
                            }
                        }
                    }
                }

                &.rp-subsidy {
                    .rp-outer-border {
                        border: 2px dotted $rp-primary-blue-700;

                        .p-card-body {
                            border: 2px solid $rp-primary-blue-800;

                            .status {
                                background-color: $rp-primary-blue-800;
                                color: $srt-white;
                            }
                        }
                    }
                }
            }
        }
    }

    .dotted-line {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
    }

    .circle {
        width: 10px;
        height: 10px;
        border-color: $rp-primary-blue-800;
        border-radius: 50%;
        display: inline-block;
        border-style: solid;
    }

    .line {
        flex-grow: 1;
        height: 2px;
        border-top: 2px dotted #ccc;
        margin: 0 5px;
    }
}

.rp-dashboard-overview-card-vendor {
    .flex-1 {
        ul {
            margin: 20px 10px !important;
            border-radius: 10px !important;
        }

        &:first-child {
            .rp-card-header {
                border-radius: 10px 0 0 10px;
                border-left: 1px solid $srt-secondary;
                .rp-card-title {
                    background-color: $rp-primary-blue-400;
                }
            }

            ul {
                border: 2px dotted $rp-primary-blue-400 !important;

                li {
                    .p-card-body {
                        border: 2px solid $rp-primary-blue-400;

                        .status {
                            background-color: $rp-primary-blue-400;
                        }
                    }
                }
            }
        }

        &:nth-child(2) {
            .rp-card-title {
                background-color: $rp-primary-blue-500;
            }

            ul {
                border: 2px dotted $rp-primary-blue-400 !important;

                li {
                    .p-card-body {
                        border: 2px solid $rp-primary-blue-500;

                        .status {
                            background-color: $rp-primary-blue-500;
                        }
                    }
                }
            }
        }

        &:nth-child(3) {
            .rp-card-title {
                background-color: $rp-primary-blue-600;
            }

            ul {
                border: 2px dotted $rp-primary-blue-500 !important;

                li {
                    .p-card-body {
                        border: 2px solid $rp-primary-blue-600;

                        .status {
                            background-color: $rp-primary-blue-600;
                        }
                    }
                }
            }
        }

        &:nth-child(4) {
            .rp-card-title {
                background-color: $rp-primary-blue-700;
            }

            ul {
                border: 2px dotted $rp-primary-blue-600 !important;

                li {
                    .p-card-body {
                        border: 2px solid $rp-primary-blue-700;

                        .status {
                            background-color: $rp-primary-blue-700;
                        }
                    }
                }
            }
        }

        &:last-child {
            .rp-card-header {
                border-radius: 0 10px 10px 0;
                border-right: 1px solid $srt-secondary;

                .rp-card-title {
                    background-color: $rp-primary-blue-800;
                }
            }

            ul {
                border: 2px dotted $rp-primary-blue-700 !important;

                li {
                    .p-card-body {
                        border: 2px solid $rp-primary-blue-800;

                        .status {
                            background-color: $rp-primary-blue-800;
                        }
                    }
                }
            }
        }
    }


    .rp-card-header {
        border-top: 1px solid $srt-secondary;
        border-bottom: 1px solid $srt-secondary;
        padding: 10px;

        .rp-card-title {
            color: $srt-white;
            border-radius: 15px;
            padding: 0.5rem 0.75rem;
            font-weight: 400;
            font-size: 0.75em;
            display: inline-block;
        }
    }

    .p-card {
        overflow: hidden;
        margin: 8px;

        .p-card-body {
            padding: 0;
            border-radius: 10px;
            background-color: $srt-secondary;

            .p-card-content {
                .amount {
                    position: relative;
                    min-height: 76px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;


                    h4,
                    h5,
                    h6 {
                        font-weight: 500;
                        line-height: 1.2;
                        text-align: center;
                        color: #000;
                    }

                    h4,
                    h6 {
                        font-size: 0.688em;
                    }

                    h5 {
                        font-size: 1.188em;
                    }
                }

                .status {
                    font-size: 0.75em;
                    font-weight: 400;
                    line-height: 1.2;
                    text-align: center;
                    color: #fff;
                    padding: 3px 0;
                }
            }
        }
    }

    .dotted-line {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
    }

    .circle {
        width: 10px;
        height: 10px;
        border-color: $rp-primary-blue-800;
        border-radius: 50%;
        display: inline-block;
        border-style: solid;
    }

    .line {
        flex-grow: 1;
        height: 2px;
        border-top: 2px dotted #ccc;
        margin: 0 5px;
    }
}

.rp-editor-text {
    a {
      display: inline-block;
    }
  }