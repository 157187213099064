.p-icon-field {
    .p-input-icon {
        position: absolute;
        top: 50%;
        margin-top: -.5rem;
    }
}

.breadcrumb-container{
    .pi{
        background: var(--heading-color);
        color: white;
        padding: 4px;
        border-radius: 11px;
    }
}

.footer-btn{
    .pi{
        margin-left:4px;
    }
}

.p-applications{
    p-iconfield{
        .p-icon-field{
            display: flex;
            gap:10px;
        } 
    }    
    border:1px solid #ccc;
    padding: 10px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}