.p-carousel {
    .p-carousel-content {
        .p-carousel-prev,
        .p-carousel-next {
            @include action-icon();
            margin: $inlineSpacing;
        }
        
    }

    .p-carousel-indicators {
        padding: $carouselIndicatorsPadding;
        position: absolute;

        .p-carousel-indicator {
            margin-right: $inlineSpacing;
            margin-bottom: $inlineSpacing;
           
            button {
                background-color: $carouselIndicatorBg;
                width: $carouselIndicatorWidth;
                height: $carouselIndicatorHeight;
                transition: $actionIconTransition;
                border-radius: $carouselIndicatorBorderRadius;

                &:hover {
                    background: $carouselIndicatorHoverBg;
                }
            }

            &.p-highlight {
                button {
                    background: $primaryWhiteColor;
                    color: $highlightTextColor;
                    width: 28px;
                    border-radius: 55px;                    
                }
            }
        }
    }
}

.p-login-carousel{
    h4{
        font-size: 1em;
        margin-bottom: 20px !important;
        font-weight: 400 !important;
        margin-top: 25px !important;
    }
    p{
        line-height: 26px !important;
        font-size: 1em !important;
        font-weight: 300 !important;
    }
}
